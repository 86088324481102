import React from 'react'
import {
    FolderOpenOutlined,
    QuestionOutlined,
    RightOutlined,
    SmileOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";
import {IntlShape} from "react-intl/src/types";
import {getI18n} from '../utils/Utils';

const HomeIcon = () => <img src="../images/home_rg_d_48_def.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }}  />;
const GraphIcon = () => <img src="../images/graph_bars_rg_d_48_def.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }}  />;
const PlugPersonIcon = () => <img src="../images/magnifying_glass_plug_person_check_mark_rg_d_48_def.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }}  />;
const SettingsIcon = () => <img src="../images/settings_rg_d_48_def.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }}  />;
const PersonsIcon = () => <img src="../images/person_rg_d_48_def.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }}  />;
const HelpIcon = () => <img src="../images/help_rg_d_48_def.png" alt="Custom Icon" style={{ width: '20px', height: '20px' }}  />;

export default function sideMenu(intl: IntlShape) {
    return {
        route: {
            routes: [
                {
                    path: "/",
                    redirect: "/welcome"
                },
                {
                    path: "/welcome",
                    name: getI18n("mainlayout-welcome", "Welcome", intl),
                    phraseId: "mainlayout-welcome",
                    icon: <HomeIcon/>,
                    component: "/pages/Welcome"
                },

                {
                    name: getI18n("mainlayout-my-onboarding", "My Onboarding", intl),
                    phraseId: "mainlayout-my-onboarding",
                    path: "/survey/surveyLandingPage",
                    icon: <PlugPersonIcon/>,
                    routes: [
                        {
                            path: "/survey/survey-overview",
                            classNames: 'menu-item-margin',
                            name: getI18n("mainlayout-survey-einvoicing", "Survey eInvoicing", intl),
                            phraseId: "mainlayout-survey-einvoicing",
                            icon: <RightOutlined/>,
                            component: "pages/survey/SurveyOverview",
                            entitlement: "menu_onboarding_einv@survey"
                        },
                        {
                            path: "/survey/survey-management",
                            name: getI18n("mainlayout-management-overview", "Management Overview", intl),
                            classNames: 'menu-item-margin',
                            phraseId: "mainlayout-management-overview",
                            icon: <RightOutlined/>,
                            component: "pages/survey/SurveyManagement",
                            entitlement: "internal_viewer@portal"
                        }
                    ]
                },
                {
                    name: intl.formatMessage({id: "mainlayout-my-documents"}),
                    path: "/documents/documents-landing",
                    phraseId: "mainlayout-my-documents",
                    icon: <FolderOpenOutlined/>,
                    routes: [
                        {
                            path: "/documents/transportation",
                            name: getI18n("mainlayout-freight", "Freight", intl),
                            phraseId: "mainlayout-freight",
                            icon: <RightOutlined/>,
                            component: "pages/documents/myDocuments",
                            entitlement: "menu_documents@freight"
                        },
                        {
                            name: getI18n("mainlayout-ecap-portal-menu", "eCAP", intl),
                            phraseId: "mainlayout-ecap-portal-menu",
                            path: "/menu",
                            component: "pages/EcapWelcome",
                            entitlement: "display_dd@ecap",
                            icon: <FolderOpenOutlined/>,
                            routes: [
                                {
                                    path: "/e-cap-welcome",
                                    name: getI18n("mainlayout-ecap-portal-hint-text", "eCap Portal", intl),
                                    phraseId: "mainlayout-ecap-portal",
                                    icon: <RightOutlined/>,
                                    component: "pages/EcapWelcome",
                                    entitlement: "display_dd@ecap"
                                },
                                {
                                    path: "/ecap/document-processing",
                                    name: getI18n("mainlayout-ecap-capture-document-hint-text", "eCAP Capture Document", intl),
                                    phraseId: "mainlayout-Capture-Document-title",
                                    icon: <RightOutlined/>,
                                    entitlement: "create_dp@ecap"
                                },
                                {
                                    path: "/ecap/manage-templates",
                                    name: getI18n("mainlayout-ecap-manage-templates-hint-text", "eCAP Manage Templates", intl),
                                    phraseId: "mainlayout-Manage-Template-title",
                                    icon: <RightOutlined/>,
                                    entitlement: "display_tp@ecap"
                                },
                                {
                                    path: "/e-cap/draft-documents",
                                    name: getI18n("mainlayout-List-Draft-Documents-hint-text", "eCAP Draft Documents", intl),
                                    phraseId: "mainlayout-List-Draft-Documents-title",
                                    icon: <RightOutlined/>,
                                    entitlement: "display_dd@ecap"
                                },
                                {
                                    path: "/e-cap/po-flip",
                                    name: getI18n("purchase-order-flip", "Purchase Order Flip", intl),
                                    phraseId: "purchase-order-flip",
                                    icon: <RightOutlined/>,
                                    entitlement: "create_dp@ecap"
                                }
                            ]
                        },
                        {
                            path: "/documents/documents-overview",
                            name: getI18n("mainlayout-List-Documents-hint-text", "Documents Overview", intl),
                            phraseId: "mainlayout-list-document",
                            icon: <RightOutlined/>,
                            component: "pages/documents/DocumentsOverview",
                            entitlement: "*overview@documents"
                        }
                    ]
                },
                {
                    name: getI18n("mainlayout-my-reports", "My Reports", intl),
                    path: "/reports/reports-landing",
                    phraseId: "mainlayout-my-reports",
                    entitlement: "display_sr@reports",
                    icon: <GraphIcon/>,
                    routes: [
                        {
                            path: "/reports/account-payable-reports",
                            name: getI18n("account-payable-reports", "Accounts Payable Reports", intl),
                            phraseId: "accounts-payable",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "pages/info-portal/components/AccountPayableReports",
                            entitlement: "display_sr@reports"
                        },
                        {
                            path: "/reports/internal-reports",
                            name: getI18n("internal-reports", "Internal Reports", intl),
                            phraseId: "internal-reports",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "pages/info-portal/components/InternalReports",
                            entitlement: "display_kp*@reports"
                        },
                        {
                            path: "/reports/tnr-reports",
                            name: getI18n("accounts-receivable", "accounts-receivable", intl),
                            phraseId: "accounts-receivable",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "pages/info-portal/components/AccountReceivableReports",
                            entitlement: "display_tnr@reports"
                        }
                    ]
                },

                {
                    name: getI18n("mainlayout-technical-management", "TECHNICAL MANAGEMENT", intl),
                    classNames: "menu-item-margin menu-item-capitalize",
                    path: "/documents/technical-dashboard-welcome-menu",
                    phraseId: "mainlayout-technical-management-portal",
                    entitlement: "technicaldashboard@systemadmin",
                    icon: <SettingsIcon/>,
                    routes: [
                        {
                            path: "/entitlements/main",
                            name: getI18n("mainlayout-entitlements-dashboard", "ENTITLEMENTS", intl),
                            phraseId: "mainlayout-entitlements-dashboard",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/entitlement_maintenance/entitlementMain",
                            entitlement: "view@entitlements"
                        },
                        {
                            path: "/documents/info-log",
                            name: getI18n("mainlayout-info-log-dashboard", "INFO-LOG", intl),
                            phraseId: "mainlayout-info-log-dashboard",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/e-cap/reprocess-info-log/InfoLogMain",
                            entitlement: "technicaldashboard@systemadmin"
                        },
                        {
                            path: "/documents/dashboard",
                            name: getI18n("mainlayout-freight-documents", "FREIGHT DOCUMENTS", intl),
                            phraseId: "mainlayout-freight-documents",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/dashboard/Dashboard",
                            entitlement: "technicaldashboard@systemadmin"
                        },
                        {
                            path: "/documents/notifications-template",
                            name: getI18n("mainlayout-notifications-template", "CREATE NOTIFICATIONS TEMPLATE", intl),
                            phraseId: "mainlayout-create-notifications-template",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/notifications/create-notifications/NotificationTemplate",
                            entitlement: "technicaldashboard@systemadmin"
                        },
                        {
                            path: "/documents/add-notification",
                            name: getI18n("mainlayout-notifications-add-notification", "ADD NOTIFICATION", intl),
                            phraseId: "mainlayout-add-notification",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/notifications/create-notifications/AddNotification",
                            entitlement: "technicaldashboard@systemadmin"
                        },
                        {
                            path: "/documents/banner-management",
                            name: getI18n("mainlayout-banner-management", "BANNER MANAGEMENT", intl).toUpperCase(),
                            phraseId: "mainlayout-banner-management",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/dashboard/BannerManagement",
                            entitlement: "technicaldashboard@systemadmin"
                        },
                        {
                            path: "/documents/missing-invoices",
                            name: getI18n("mainlayout-missing-invoices", "ECAP MISSING INVOICES", intl).toUpperCase(),
                            phraseId: "mainlayout-missing-invoices",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/dashboard/missing_invoices/TechMissingInvoices",
                            entitlement: "technicaldashboard@systemadmin"
                        },
                        {
                            path: "/reports/admin",
                            name: getI18n("report-admin-menu-label", "Reports Admin", intl).toUpperCase(),
                            phraseId: "report-admin-menu-label",
                            classNames: "menu-item-margin menu-item-capitalize",
                            icon: <RightOutlined/>,
                            component: "/pages/info-portal/components/admin/ReportingConfiguration",
                            entitlement: "config_admin@reports"
                        }


                    ]
                },
                {
                    name: getI18n("mainlayout-user-management", "User Management", intl),
                    phraseId: "mainlayout-user-management",
                    icon: <PersonsIcon/>,
                    path: "/users",
                    component: "/pages/Users",
                    entitlement: "usermanagement@systemadmin"
                },
                {
                    path: "/help-faq-user-guides",
                    name: getI18n("mainlayout-help", "Help / FAQ", intl).toUpperCase(),
                    phraseId: "mainlayout-help",
                    icon: <HelpIcon/>,
                    entitlement: "*"
                }
            ]
        },
        location: {
            pathname: '/'
        }
    }
}