import { IAppComponentProps } from "../../../components";
import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, FormInstance, Input, message, Row, Select, Spin } from "antd";
import { getI18n, isExternalUser } from "../../../utils/Utils";
import { FormattedMessage, useIntl } from "react-intl";
import {  useLazyQuery } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import {
  isBmwGroupCompany,
  setLoading,
  setPartnerName,
  setPartnerTaxId,
  setPartnerVatCode,
  setPartnerVatCountryCode,
  setTemplateCount,
  updateBMWBusinessAreaId,
  updateBMWBusinessUnitId,
  updateBMWRetailOutlet,
  updateCompanyCode,
  updateCompanyVatCodeId,
  updatePartnerNo,
  updatePartnerVatCodeId,
  updateSupplier, updateSystemCode,
} from '../IStoredDocumentRecordSlice';
import { updateSelectedPartner } from "../IStoredPartnerRecordSlice";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from "../../../main/__generated__/getSelfEntitlementResultByFilter";
import { Form_Item } from "../componets/FormItem-component";
import { getControlRuleset } from "../RulesEngine";
import { ECAP_USER_CAPTURE_ENTITLEMENT_BY_USERID } from '../../../main/queries';
import { MY_DOCS_TEMPLATE_PARTNER_COUNT } from "../templates/queries";
import { getTemplatePartnerCount, getTemplatePartnerCountVariables } from "../templates/__generated__/getTemplatePartnerCount";
import {   getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes } from "../__generated__/getPartnerVATInfoByPartnerNo";
import _ from "lodash";
import {
  GetEcapUserCaptureEntitlementResultByUserIdQuery,
  useGetEcapSelfEntitlementResultByFilterLazyQuery,
} from '../../../main/queries.generated';
import { EcapEntitlementPartner } from '../../../__generated__/types';
import { updateSelectedEcapEntitlementPartner } from '../IStoredEcapPartnerEntitlementSlice';

type SuppliersHeaderProps = {
  form: FormInstance<any>;
  templateMode: boolean;
  vatSelectOption: getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes[];
} & IAppComponentProps;
const capture_Entitlement = "create_dp@ecap";


const SupplierHeader: React.FunctionComponent<SuppliersHeaderProps> = (props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [selectOptions, setSelectOption] = useState<getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]|EcapEntitlementPartner[]>(undefined);
  const [vatCode, setVatCode] = useState<string>(undefined);
  const [loading, setPartnerListLoading] = useState<boolean>(false);
  const { document } = useAppSelector((state) => state.document);
  const  rules = useAppSelector((state) => state.rules);
  const [componentLoaded, setComponentLoaded] = useState<boolean>(false);
  const { partnerRecord } = useAppSelector((state) => state.partnerRecord);

  const [getEcapSelfEntitlementResultByFilter, {}] = useGetEcapSelfEntitlementResultByFilterLazyQuery();

  const removeDuplicated = (data: EcapEntitlementPartner[]) => Array.from(
    data
      .reduce(
        (acc, item) => (
          item && item["partnerId"] && acc.set(item["partnerId"], item),
            acc
        ),
        new Map()
      )
      .values());

  const onlyShowVendorPartners = (data: EcapEntitlementPartner[]) => Array.from(
        data
        .filter(item => item.category === 'V')
        .values());

  const searchPartner = async (partnerName) => {
    getEcapSelfEntitlementResultByFilter({
      variables: {
        entitlement: capture_Entitlement,
        partnerName,
        pageNumber: 1,
        pageSize: 200
      }
    })
     .then((response) => {
          const searchOption = onlyShowVendorPartners(removeDuplicated(response.data.getEcapSelfEntitlementResultByFilter.partners));
           setSelectOption(searchOption);
        })
        .catch((err) => {
          console.error(err);
          setPartnerListLoading(false);
        }).finally(() => {
          setPartnerListLoading(false);
        });
  };

  const [getUserEntitlement, {}] = useLazyQuery<GetEcapUserCaptureEntitlementResultByUserIdQuery>(ECAP_USER_CAPTURE_ENTITLEMENT_BY_USERID, {
    variables: { userId: props.currentUser?.uid, authentication: localStorage.getItem("id_token") },
    fetchPolicy: "cache-first"
  });

  const [getPartnerCount, {}] = useLazyQuery<getTemplatePartnerCount, getTemplatePartnerCountVariables>(MY_DOCS_TEMPLATE_PARTNER_COUNT, {
    fetchPolicy: "network-only", onError: (error) => console.log(error)
  });


  const checkPartnerEntitlement = () => {
    if (isExternalUser(props.distinctEntitlements)) {
      dispatch(setLoading(true));
      getUserEntitlement().then(response => {
        const userEntitlement = response.data?.getEcapUserCaptureEntitlementResultByUserId;
        if (userEntitlement?.partners?.length === 1) {
          dispatch(updateSupplier(userEntitlement?.partners[0].partnerId));
          dispatch(updatePartnerNo(userEntitlement?.partners[0].partnerNo));
          dispatch(updateSystemCode(userEntitlement?.partners[0].systemCode));
          dispatch(updateSelectedPartner(userEntitlement.partners[0]));
          const fields = props.form.getFieldsValue();
          const { supplierDetails } = fields;
          const newSupplierDetails = {
            ...supplierDetails,
            "supplierName": `${userEntitlement.partners[0]?.partnerNo} - ${userEntitlement.partners[0]?.partnerName}`
          };
          props.form.setFieldsValue({ supplierDetails: newSupplierDetails });
        }
        if (userEntitlement?.partners?.length > 1) {
          const searchOption = removeDuplicated(onlyShowVendorPartners(userEntitlement?.partners));
          console.log("Partner Numbers");
          console.log(searchOption);
          setSelectOption(searchOption);
        }
        dispatch(setLoading(false));
        
      });
    }
  };


  const handleSearch = _.debounce(function (value: string) {
    console.log(`Handle search: ${value}, partner selected: ${document.partnerNo}`);
    // isLoading(true);
    setComponentLoaded(true);
    setPartnerListLoading(true);
    searchPartner(value);
  }, 1000);


/*  const findSelectedPartner = (partnerId) => {
    if (selectOptions !== undefined)
    {
      return selectOptions.find((item) => {
        if (item.partnerId === partnerId) {
          dispatch(updatePartnerNo(item.partnerNo));
          return item;
        }
      });
    }
  };*/

  const partnerVatCodeChange = (value) => {
    dispatch(updatePartnerVatCodeId(Number(value)));

    const vatCodeSelected = props.vatSelectOption.filter((vatCode) => {
      return vatCode.partnerVatId === Number(value)
    })
    if (vatCodeSelected !== null && vatCodeSelected !== undefined && vatCodeSelected.length > 0 &&  vatCodeSelected.at(0).vatCode !== null &&  vatCodeSelected.at(0).country !== null)
    {
      dispatch(setPartnerVatCode(vatCodeSelected.at(0).vatCode));
      dispatch(setPartnerVatCountryCode(vatCodeSelected.at(0).country));
    }
    else{
      dispatch(setPartnerVatCode(null));
      dispatch(setPartnerVatCountryCode(null));
    }


  };

  const handleSelect = (value) => {

    getEcapSelfEntitlementResultByFilter({
      variables: {
        entitlement: capture_Entitlement,
        partnerName: value,
        pageNumber: 1,
        pageSize: 200
      }
    })
        .then((response) => {
          const selectedPartner  =  response.data.getEcapSelfEntitlementResultByFilter.partners.length ===1?
          response.data.getEcapSelfEntitlementResultByFilter.partners[0] : response.data.getEcapSelfEntitlementResultByFilter.partners
                  .filter(item => item.companies.length > 1)[0];
          console.log(`Partner Selected : ${selectedPartner.partnerNo}`);
          dispatch(updateSupplier(value));
          dispatch(updatePartnerNo(selectedPartner.partnerNo));
          dispatch(setPartnerName(selectedPartner.partnerName));
          dispatch(updatePartnerVatCodeId(undefined));
          const document = props.form.getFieldValue("document");
          document.partnerVatCodeId = undefined;
          //
          if(response.data.getEcapSelfEntitlementResultByFilter.partners.length ===1 &&
              response.data.getEcapSelfEntitlementResultByFilter.partners[0].companies.length === 1) {
            dispatch(updateSelectedPartner(response.data.getEcapSelfEntitlementResultByFilter.partners[0]));
          }

          if (props.templateMode) {
            getPartnerCount({ variables: { partnerNo: selectedPartner.partnerNo } })
                .then((response) => {
                  const count: number = response.data.getTemplatePartnerCount;
                  //console.log(`Number of Templates for partner is : ${count}`);
                  dispatch(setTemplateCount(count));
                });
          }
          //dispatch(updateSelectedPartner(selectedPartner));
          dispatch(updateSelectedEcapEntitlementPartner(response.data.getEcapSelfEntitlementResultByFilter.partners.filter(item => item.category === 'V')));
        })
        .catch((err) => {
          console.error(err);
          setPartnerListLoading(false);
        }).finally(() => {
      setPartnerListLoading(false);
    });


  }

  const handleChange = (value) => {
    console.log(`Partner field changed : ${value}`);
    //clear company code fields and state
    props.form.setFieldsValue({ "companyCodeDisplayFld": "" });
    dispatch(updateCompanyCode(undefined));
    props.form.setFieldsValue({ "companyTaxCode": undefined });
    props.form.setFieldsValue({ "companyVatCode": undefined });
    dispatch(updateCompanyVatCodeId(undefined));
    dispatch(updateBMWBusinessAreaId(undefined));
    dispatch(updateBMWBusinessUnitId(undefined));
    dispatch(updateBMWRetailOutlet(false));
    dispatch(updatePartnerVatCodeId(undefined));

    props.form.setFieldsValue({
      bmwRetailOutlet: getI18n('ECAP-capture-BMW-retail-outlet-choice-no', 'No', intl),
      });
    props.form.setFieldsValue({ bmwBusinessArea: undefined });
    props.form.setFieldsValue({ bmwBusinessUnit: undefined });
    //
    props.form.setFieldsValue({ "vatCode": "" });
    //

  };

  const checkTemplateDetails = () => {
    //console.log(`Load Partner details with partner no : ${partnerRecord.partnerNo}`);
    //
    if (props.templateMode)
    {
      getPartnerCount({ variables: { partnerNo: partnerRecord.partnerNo } })
      .then((response) => {
        const count: number = response.data.getTemplatePartnerCount;
        //console.log(`Number of Templates for partner is : ${count}`);
        dispatch(setTemplateCount(count));

      });
    }
    
  };

  const setBmwGroupCompany = (e: CheckboxChangeEvent) => {
    dispatch(isBmwGroupCompany(e.target.checked));
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  };

  useEffect(() => {
    console.log("Initiate Partner header component");
    checkPartnerEntitlement();
    //Check if component initialize and supplier code was set in state then load supplier details
    // console.log(`component Loaded : ${componentLoaded} , selected supplier: `);
    // console.dir(partnerRecord);
    if (!componentLoaded && partnerRecord !== undefined && partnerRecord.partnerNo !== undefined)
    {
      console.log("load supplier details for edit mode");
      handleSearch(partnerRecord.partnerNo);
      checkTemplateDetails();

    }

  }, [partnerRecord, props.vatSelectOption]);



  const bmwCompanyMassage = getI18n("supplierheader-bmw-group-company-tool-tip",
                    "Please select for BEV and PHEV Payment Requests", intl);
  const onChangeTaxId = (e) => {
    dispatch(setPartnerTaxId(e.target.value));
  }

  return (
    <>
      <Row gutter={24}>
        <Col span={10}>
          <Spin size={'large'} spinning={loading}>
            <Form.Item name={["supplierDetails", "supplierName"]} required={true}
                      label={getI18n("supplierheader-supplier-name" ,"Supplier", intl)} >
              <Select style={{ width: "100%" }}
                      size={"middle"}
                      showSearch={true}
                      placeholder={getI18n("supplierheader-supplier-search-message",
                                                    "Enter partner number or partner name", intl)}
                      onSearch={handleSearch}
                      onChange={handleChange}
                      onSelect={handleSelect}
                      defaultActiveFirstOption={true}
                      filterOption={false}
                      notFoundContent={null}
                      loading={document.loading > 0}
                      allowClear={true}
              >
                {selectOptions &&
                selectOptions.map((item) => (
                  <Select.Option key={`${item.partnerId}_${item.partnerNo}`} value={item.partnerNo}>
                    {item.partnerNo} - {item.partnerName}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>
          </Spin>
        </Col>
        <Col span={7}>
          {props?.vatSelectOption?.length
          ?
            <Form_Item name={["supplierDetails", "partnerVatCode"]}
            label={getI18n("supplierheader-supplier-vat","Supplier VAT Code", intl)}
            itemRules={getControlRuleset('SUPPLIER_VAT_ID',props.form,rules,document,null)}
            >
            {props.vatSelectOption &&
            props.vatSelectOption.length <= 1 ?
              <Input readOnly={true} allowClear={true} />
              :
              <Select value={vatCode} loading={document.loading > 0}
                      size={"middle"} placeholder={getI18n("supplierheader-supplier-vat",
                                                                      "Supplier VAT Code", intl)}
                      onChange={partnerVatCodeChange} allowClear={true}>
                {props.vatSelectOption &&
                props.vatSelectOption.map((item) => (
                  <Select.Option key={`${item.country}_${item.vatCode}`} value={item.partnerVatId}>
                    {item.vatCode}
                  </Select.Option>
                ))}
              </Select>}
            </Form_Item>

          :
            <Form.Item name={["supplierDetails", "partnerVatCode"]}
              label={getI18n("supplierheader-supplier-vat","Supplier VAT Code", intl)}
              >
              <Input readOnly={true} allowClear={true} />
            </Form.Item>
          }

        </Col>

        <Col span={7}>
          <Form.Item name={"taxId"}
                     label={getI18n("supplierheader-supplier-tax", "Supplier Tax ID", intl)}
                    //  itemRules={getControlRuleset('GENERAL_TAX_NUMBER',props.form,rules,document,null)}
                    
                     >
            <Input
              placeholder={intl.formatMessage({ id: "supplierheader-supplier-tax", defaultMessage: "Supplier Tax ID" })}
              allowClear={true}
              onChange={onChangeTaxId}
              />
          </Form.Item>
        </Col>
      </Row>


    </>
  );
};

export default SupplierHeader;