import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectOptionQueryVariables = Types.Exact<{
  select: Types.Scalars['String']['input'];
  username: Types.Scalars['String']['input'];
}>;


export type SelectOptionQuery = { __typename?: 'Query', getSelectDropDown?: Array<{ __typename?: 'SelectDropdowns', id: string, name: string, value: string }> | null };

export type SelectOptionEntitlementQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
  entitlement: Types.Scalars['String']['input'];
}>;


export type SelectOptionEntitlementQuery = { __typename?: 'Query', getSelectDropDownFromCoCodeEntitlement?: Array<{ __typename?: 'SelectDropdowns', id: string, name: string, value: string }> | null };

export type AllPartnersForUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['Float']['input'];
}>;


export type AllPartnersForUserQuery = { __typename?: 'Query', allPartnersForUser?: Array<{ __typename?: 'Partner', partnerId: number, partnerNo: string, partnerName: string, category?: string | null }> | null };

export type AllPartnersByPartnerNoQueryVariables = Types.Exact<{
  partnerNo: Types.Scalars['String']['input'];
}>;


export type AllPartnersByPartnerNoQuery = { __typename?: 'Query', allPartnersByPartnerNo?: Array<{ __typename?: 'AssignedPartner', partnerId?: number | null, partnerNo?: string | null, partnerName?: string | null, companyId?: number | null, companyCode?: string | null, companyName?: string | null, roleId?: string | null, roleName?: string | null, systemId?: string | null, systemName?: string | null, systemCode?: string | null, companyPartnerId?: number | null }> | null };

export type GetPortalUsersQueryVariables = Types.Exact<{
  portalUserInput: Types.PortalUserInput;
}>;


export type GetPortalUsersQuery = { __typename?: 'Query', getPortalUser?: Array<{ __typename?: 'PortalUser', user_id?: number | null, username?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, roleName?: string | null }> | null };

export type GetAllPortalUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllPortalUsersQuery = { __typename?: 'Query', getAllPortalUsers?: Array<{ __typename?: 'PortalUser', user_id?: number | null, role_id?: number | null, username?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, roleName?: string | null, insert_time?: string | null }> | null };

export type SearchPortalUsersQueryVariables = Types.Exact<{
  portalUserInput: Types.PortalUserInput;
}>;


export type SearchPortalUsersQuery = { __typename?: 'Query', searchPortalUsers?: Array<{ __typename?: 'PortalUserExtended', userId?: number | null, roleId?: number | null, userName?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, objectClass?: string | null, employeeType?: string | null }> | null };

export type GetPartnerListQueryVariables = Types.Exact<{
  userId: Types.Scalars['Float']['input'];
}>;


export type GetPartnerListQuery = { __typename?: 'Query', getPartnerForUser?: Array<{ __typename?: 'Partner', partnerId: number, partnerName: string, partnerNo: string }> | null };

export type AllProcessCodeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllProcessCodeQuery = { __typename?: 'Query', allProcessCode?: Array<{ __typename?: 'SelectDropdowns', id: string, name: string, value: string }> | null };

export type AllProcessCodeByUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['Float']['input'];
}>;


export type AllProcessCodeByUserQuery = { __typename?: 'Query', allProcessCodeByUser?: Array<{ __typename?: 'Process', processCodeId: number, processCode: string, processName: string }> | null };

export type AllCompaniesForUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['Float']['input'];
}>;


export type AllCompaniesForUserQuery = { __typename?: 'Query', allCompaniesForUser?: Array<{ __typename?: 'AssignedCompany', companyId?: number | null, companyCode?: string | null, companyName?: string | null, roleId?: number | null, roleName?: string | null, systemId?: number | null, systemName?: string | null, systemCode?: string | null }> | null };

export type AllCompaniesByPartnerNumberQueryVariables = Types.Exact<{
  partnerno: Types.Scalars['String']['input'];
  rolename: Types.Scalars['String']['input'];
}>;


export type AllCompaniesByPartnerNumberQuery = { __typename?: 'Query', allCompaniesByPartnerNumber?: Array<{ __typename?: 'AssignedCompany', companyId?: number | null, companyCode?: string | null, companyName?: string | null, roleId?: number | null, roleName?: string | null, systemId?: number | null, systemName?: string | null, systemCode?: string | null }> | null };

export type MutateAssignedUserRightsMutationVariables = Types.Exact<{
  userId: Types.Scalars['Float']['input'];
  userRights: Array<Types.UserRightsInput> | Types.UserRightsInput;
}>;


export type MutateAssignedUserRightsMutation = { __typename?: 'Mutation', mutateAssignedUserRights?: Array<{ __typename?: 'UserRightsOutput', rowno?: number | null, userId?: number | null, userName?: string | null, partnerId?: number | null, partnerNo?: string | null, partnerName?: string | null, roleName?: string | null, roleId?: number | null, processCodeId?: number | null, processCode?: string | null, processCodeName?: string | null, companyLinkExists?: number | null, companyCode?: string | null, companyName?: string | null, companyId?: number | null, systemCompanyId?: number | null, systemId?: number | null, systemCode?: string | null, systemName?: string | null, companyPartnerId?: number | null, assigned?: number | null }> | null };

export type PreferencesGetApiQueryVariables = Types.Exact<{
  preference: Types.Scalars['String']['input'];
}>;


export type PreferencesGetApiQuery = { __typename?: 'Query', userPreferences?: Array<{ __typename?: 'DisplayPreference', id?: number | null, code?: string | null, description?: string | null }> | null };

export type GetAllUserRightsQueryVariables = Types.Exact<{
  userId: Types.Scalars['Float']['input'];
}>;


export type GetAllUserRightsQuery = { __typename?: 'Query', getAllUserRights?: Array<{ __typename?: 'UserRightsOutput', rowno?: number | null, userId?: number | null, userName?: string | null, partnerId?: number | null, partnerNo?: string | null, partnerName?: string | null, roleName?: string | null, roleId?: number | null, processCodeId?: number | null, processCode?: string | null, processCodeName?: string | null, companyLinkExists?: number | null, companyCode?: string | null, companyName?: string | null, companyId?: number | null, systemCompanyId?: number | null, systemId?: number | null, systemCode?: string | null, systemName?: string | null, companyPartnerId?: number | null, assigned?: number | null, insertTime?: string | null, insertUser?: string | null }> | null };

export type ValidateUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ValidateUserQuery = { __typename?: 'Query', validateUser?: { __typename?: 'ValidateUserResponse', success?: boolean | null, errorCode?: string | null, errorDescription?: string | null, portalUser?: { __typename?: 'PortalUser', user_id?: number | null, role_id?: number | null, username?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, roleName?: string | null, realm?: string | null, uid?: string | null, departmentnumber?: string | null, roles?: Array<string> | null } | null, userPreferences?: { __typename?: 'Preferences', language?: string | null, decimalSeparator?: string | null, thousandSeparator?: string | null, dateFormat?: string | null, userSettingsId?: number | null } | null } | null };


export const SelectOptionDocument = gql`
    query selectOption($select: String!, $username: String!) {
  getSelectDropDown(select: $select, username: $username) {
    id
    name
    value
  }
}
    `;

/**
 * __useSelectOptionQuery__
 *
 * To run a query within a React component, call `useSelectOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectOptionQuery({
 *   variables: {
 *      select: // value for 'select'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSelectOptionQuery(baseOptions: Apollo.QueryHookOptions<SelectOptionQuery, SelectOptionQueryVariables> & ({ variables: SelectOptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectOptionQuery, SelectOptionQueryVariables>(SelectOptionDocument, options);
      }
export function useSelectOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectOptionQuery, SelectOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectOptionQuery, SelectOptionQueryVariables>(SelectOptionDocument, options);
        }
export function useSelectOptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectOptionQuery, SelectOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectOptionQuery, SelectOptionQueryVariables>(SelectOptionDocument, options);
        }
export type SelectOptionQueryHookResult = ReturnType<typeof useSelectOptionQuery>;
export type SelectOptionLazyQueryHookResult = ReturnType<typeof useSelectOptionLazyQuery>;
export type SelectOptionSuspenseQueryHookResult = ReturnType<typeof useSelectOptionSuspenseQuery>;
export type SelectOptionQueryResult = Apollo.QueryResult<SelectOptionQuery, SelectOptionQueryVariables>;
export const SelectOptionEntitlementDocument = gql`
    query selectOptionEntitlement($uid: String!, $entitlement: String!) {
  getSelectDropDownFromCoCodeEntitlement(uid: $uid, entitlement: $entitlement) {
    id
    name
    value
  }
}
    `;

/**
 * __useSelectOptionEntitlementQuery__
 *
 * To run a query within a React component, call `useSelectOptionEntitlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectOptionEntitlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectOptionEntitlementQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      entitlement: // value for 'entitlement'
 *   },
 * });
 */
export function useSelectOptionEntitlementQuery(baseOptions: Apollo.QueryHookOptions<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables> & ({ variables: SelectOptionEntitlementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables>(SelectOptionEntitlementDocument, options);
      }
export function useSelectOptionEntitlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables>(SelectOptionEntitlementDocument, options);
        }
export function useSelectOptionEntitlementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables>(SelectOptionEntitlementDocument, options);
        }
export type SelectOptionEntitlementQueryHookResult = ReturnType<typeof useSelectOptionEntitlementQuery>;
export type SelectOptionEntitlementLazyQueryHookResult = ReturnType<typeof useSelectOptionEntitlementLazyQuery>;
export type SelectOptionEntitlementSuspenseQueryHookResult = ReturnType<typeof useSelectOptionEntitlementSuspenseQuery>;
export type SelectOptionEntitlementQueryResult = Apollo.QueryResult<SelectOptionEntitlementQuery, SelectOptionEntitlementQueryVariables>;
export const AllPartnersForUserDocument = gql`
    query allPartnersForUser($userId: Float!) {
  allPartnersForUser(userId: $userId) {
    partnerId
    partnerNo
    partnerName
    category
  }
}
    `;

/**
 * __useAllPartnersForUserQuery__
 *
 * To run a query within a React component, call `useAllPartnersForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartnersForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartnersForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllPartnersForUserQuery(baseOptions: Apollo.QueryHookOptions<AllPartnersForUserQuery, AllPartnersForUserQueryVariables> & ({ variables: AllPartnersForUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPartnersForUserQuery, AllPartnersForUserQueryVariables>(AllPartnersForUserDocument, options);
      }
export function useAllPartnersForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPartnersForUserQuery, AllPartnersForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPartnersForUserQuery, AllPartnersForUserQueryVariables>(AllPartnersForUserDocument, options);
        }
export function useAllPartnersForUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllPartnersForUserQuery, AllPartnersForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllPartnersForUserQuery, AllPartnersForUserQueryVariables>(AllPartnersForUserDocument, options);
        }
export type AllPartnersForUserQueryHookResult = ReturnType<typeof useAllPartnersForUserQuery>;
export type AllPartnersForUserLazyQueryHookResult = ReturnType<typeof useAllPartnersForUserLazyQuery>;
export type AllPartnersForUserSuspenseQueryHookResult = ReturnType<typeof useAllPartnersForUserSuspenseQuery>;
export type AllPartnersForUserQueryResult = Apollo.QueryResult<AllPartnersForUserQuery, AllPartnersForUserQueryVariables>;
export const AllPartnersByPartnerNoDocument = gql`
    query allPartnersByPartnerNo($partnerNo: String!) {
  allPartnersByPartnerNo(partnerNo: $partnerNo) {
    partnerId
    partnerNo
    partnerName
    companyId
    companyCode
    companyName
    roleId
    roleName
    systemId
    systemName
    systemCode
    companyPartnerId
  }
}
    `;

/**
 * __useAllPartnersByPartnerNoQuery__
 *
 * To run a query within a React component, call `useAllPartnersByPartnerNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartnersByPartnerNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartnersByPartnerNoQuery({
 *   variables: {
 *      partnerNo: // value for 'partnerNo'
 *   },
 * });
 */
export function useAllPartnersByPartnerNoQuery(baseOptions: Apollo.QueryHookOptions<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables> & ({ variables: AllPartnersByPartnerNoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables>(AllPartnersByPartnerNoDocument, options);
      }
export function useAllPartnersByPartnerNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables>(AllPartnersByPartnerNoDocument, options);
        }
export function useAllPartnersByPartnerNoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables>(AllPartnersByPartnerNoDocument, options);
        }
export type AllPartnersByPartnerNoQueryHookResult = ReturnType<typeof useAllPartnersByPartnerNoQuery>;
export type AllPartnersByPartnerNoLazyQueryHookResult = ReturnType<typeof useAllPartnersByPartnerNoLazyQuery>;
export type AllPartnersByPartnerNoSuspenseQueryHookResult = ReturnType<typeof useAllPartnersByPartnerNoSuspenseQuery>;
export type AllPartnersByPartnerNoQueryResult = Apollo.QueryResult<AllPartnersByPartnerNoQuery, AllPartnersByPartnerNoQueryVariables>;
export const GetPortalUsersDocument = gql`
    query getPortalUsers($portalUserInput: PortalUserInput!) {
  getPortalUser(portalUserInput: $portalUserInput) {
    user_id
    username
    firstname
    lastname
    email
    roleName
  }
}
    `;

/**
 * __useGetPortalUsersQuery__
 *
 * To run a query within a React component, call `useGetPortalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortalUsersQuery({
 *   variables: {
 *      portalUserInput: // value for 'portalUserInput'
 *   },
 * });
 */
export function useGetPortalUsersQuery(baseOptions: Apollo.QueryHookOptions<GetPortalUsersQuery, GetPortalUsersQueryVariables> & ({ variables: GetPortalUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPortalUsersQuery, GetPortalUsersQueryVariables>(GetPortalUsersDocument, options);
      }
export function useGetPortalUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPortalUsersQuery, GetPortalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPortalUsersQuery, GetPortalUsersQueryVariables>(GetPortalUsersDocument, options);
        }
export function useGetPortalUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPortalUsersQuery, GetPortalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPortalUsersQuery, GetPortalUsersQueryVariables>(GetPortalUsersDocument, options);
        }
export type GetPortalUsersQueryHookResult = ReturnType<typeof useGetPortalUsersQuery>;
export type GetPortalUsersLazyQueryHookResult = ReturnType<typeof useGetPortalUsersLazyQuery>;
export type GetPortalUsersSuspenseQueryHookResult = ReturnType<typeof useGetPortalUsersSuspenseQuery>;
export type GetPortalUsersQueryResult = Apollo.QueryResult<GetPortalUsersQuery, GetPortalUsersQueryVariables>;
export const GetAllPortalUsersDocument = gql`
    query getAllPortalUsers {
  getAllPortalUsers {
    user_id
    role_id
    username
    firstname
    lastname
    email
    roleName
    insert_time
  }
}
    `;

/**
 * __useGetAllPortalUsersQuery__
 *
 * To run a query within a React component, call `useGetAllPortalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPortalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPortalUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPortalUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>(GetAllPortalUsersDocument, options);
      }
export function useGetAllPortalUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>(GetAllPortalUsersDocument, options);
        }
export function useGetAllPortalUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>(GetAllPortalUsersDocument, options);
        }
export type GetAllPortalUsersQueryHookResult = ReturnType<typeof useGetAllPortalUsersQuery>;
export type GetAllPortalUsersLazyQueryHookResult = ReturnType<typeof useGetAllPortalUsersLazyQuery>;
export type GetAllPortalUsersSuspenseQueryHookResult = ReturnType<typeof useGetAllPortalUsersSuspenseQuery>;
export type GetAllPortalUsersQueryResult = Apollo.QueryResult<GetAllPortalUsersQuery, GetAllPortalUsersQueryVariables>;
export const SearchPortalUsersDocument = gql`
    query searchPortalUsers($portalUserInput: PortalUserInput!) {
  searchPortalUsers(portalUserInput: $portalUserInput) {
    userId
    roleId
    userName
    firstName
    lastName
    email
    objectClass
    employeeType
  }
}
    `;

/**
 * __useSearchPortalUsersQuery__
 *
 * To run a query within a React component, call `useSearchPortalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPortalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPortalUsersQuery({
 *   variables: {
 *      portalUserInput: // value for 'portalUserInput'
 *   },
 * });
 */
export function useSearchPortalUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchPortalUsersQuery, SearchPortalUsersQueryVariables> & ({ variables: SearchPortalUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPortalUsersQuery, SearchPortalUsersQueryVariables>(SearchPortalUsersDocument, options);
      }
export function useSearchPortalUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPortalUsersQuery, SearchPortalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPortalUsersQuery, SearchPortalUsersQueryVariables>(SearchPortalUsersDocument, options);
        }
export function useSearchPortalUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchPortalUsersQuery, SearchPortalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchPortalUsersQuery, SearchPortalUsersQueryVariables>(SearchPortalUsersDocument, options);
        }
export type SearchPortalUsersQueryHookResult = ReturnType<typeof useSearchPortalUsersQuery>;
export type SearchPortalUsersLazyQueryHookResult = ReturnType<typeof useSearchPortalUsersLazyQuery>;
export type SearchPortalUsersSuspenseQueryHookResult = ReturnType<typeof useSearchPortalUsersSuspenseQuery>;
export type SearchPortalUsersQueryResult = Apollo.QueryResult<SearchPortalUsersQuery, SearchPortalUsersQueryVariables>;
export const GetPartnerListDocument = gql`
    query getPartnerList($userId: Float!) {
  getPartnerForUser(userId: $userId) {
    partnerId
    partnerName
    partnerNo
  }
}
    `;

/**
 * __useGetPartnerListQuery__
 *
 * To run a query within a React component, call `useGetPartnerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPartnerListQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerListQuery, GetPartnerListQueryVariables> & ({ variables: GetPartnerListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerListQuery, GetPartnerListQueryVariables>(GetPartnerListDocument, options);
      }
export function useGetPartnerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerListQuery, GetPartnerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerListQuery, GetPartnerListQueryVariables>(GetPartnerListDocument, options);
        }
export function useGetPartnerListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartnerListQuery, GetPartnerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerListQuery, GetPartnerListQueryVariables>(GetPartnerListDocument, options);
        }
export type GetPartnerListQueryHookResult = ReturnType<typeof useGetPartnerListQuery>;
export type GetPartnerListLazyQueryHookResult = ReturnType<typeof useGetPartnerListLazyQuery>;
export type GetPartnerListSuspenseQueryHookResult = ReturnType<typeof useGetPartnerListSuspenseQuery>;
export type GetPartnerListQueryResult = Apollo.QueryResult<GetPartnerListQuery, GetPartnerListQueryVariables>;
export const AllProcessCodeDocument = gql`
    query allProcessCode {
  allProcessCode {
    id
    name
    value
  }
}
    `;

/**
 * __useAllProcessCodeQuery__
 *
 * To run a query within a React component, call `useAllProcessCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProcessCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProcessCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProcessCodeQuery(baseOptions?: Apollo.QueryHookOptions<AllProcessCodeQuery, AllProcessCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProcessCodeQuery, AllProcessCodeQueryVariables>(AllProcessCodeDocument, options);
      }
export function useAllProcessCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProcessCodeQuery, AllProcessCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProcessCodeQuery, AllProcessCodeQueryVariables>(AllProcessCodeDocument, options);
        }
export function useAllProcessCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllProcessCodeQuery, AllProcessCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllProcessCodeQuery, AllProcessCodeQueryVariables>(AllProcessCodeDocument, options);
        }
export type AllProcessCodeQueryHookResult = ReturnType<typeof useAllProcessCodeQuery>;
export type AllProcessCodeLazyQueryHookResult = ReturnType<typeof useAllProcessCodeLazyQuery>;
export type AllProcessCodeSuspenseQueryHookResult = ReturnType<typeof useAllProcessCodeSuspenseQuery>;
export type AllProcessCodeQueryResult = Apollo.QueryResult<AllProcessCodeQuery, AllProcessCodeQueryVariables>;
export const AllProcessCodeByUserDocument = gql`
    query allProcessCodeByUser($userId: Float!) {
  allProcessCodeByUser(userId: $userId) {
    processCodeId
    processCode
    processName
  }
}
    `;

/**
 * __useAllProcessCodeByUserQuery__
 *
 * To run a query within a React component, call `useAllProcessCodeByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProcessCodeByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProcessCodeByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllProcessCodeByUserQuery(baseOptions: Apollo.QueryHookOptions<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables> & ({ variables: AllProcessCodeByUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables>(AllProcessCodeByUserDocument, options);
      }
export function useAllProcessCodeByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables>(AllProcessCodeByUserDocument, options);
        }
export function useAllProcessCodeByUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables>(AllProcessCodeByUserDocument, options);
        }
export type AllProcessCodeByUserQueryHookResult = ReturnType<typeof useAllProcessCodeByUserQuery>;
export type AllProcessCodeByUserLazyQueryHookResult = ReturnType<typeof useAllProcessCodeByUserLazyQuery>;
export type AllProcessCodeByUserSuspenseQueryHookResult = ReturnType<typeof useAllProcessCodeByUserSuspenseQuery>;
export type AllProcessCodeByUserQueryResult = Apollo.QueryResult<AllProcessCodeByUserQuery, AllProcessCodeByUserQueryVariables>;
export const AllCompaniesForUserDocument = gql`
    query allCompaniesForUser($userId: Float!) {
  allCompaniesForUser(userId: $userId) {
    companyId
    companyCode
    companyName
    roleId
    roleName
    systemId
    systemName
    systemCode
  }
}
    `;

/**
 * __useAllCompaniesForUserQuery__
 *
 * To run a query within a React component, call `useAllCompaniesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllCompaniesForUserQuery(baseOptions: Apollo.QueryHookOptions<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables> & ({ variables: AllCompaniesForUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables>(AllCompaniesForUserDocument, options);
      }
export function useAllCompaniesForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables>(AllCompaniesForUserDocument, options);
        }
export function useAllCompaniesForUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables>(AllCompaniesForUserDocument, options);
        }
export type AllCompaniesForUserQueryHookResult = ReturnType<typeof useAllCompaniesForUserQuery>;
export type AllCompaniesForUserLazyQueryHookResult = ReturnType<typeof useAllCompaniesForUserLazyQuery>;
export type AllCompaniesForUserSuspenseQueryHookResult = ReturnType<typeof useAllCompaniesForUserSuspenseQuery>;
export type AllCompaniesForUserQueryResult = Apollo.QueryResult<AllCompaniesForUserQuery, AllCompaniesForUserQueryVariables>;
export const AllCompaniesByPartnerNumberDocument = gql`
    query allCompaniesByPartnerNumber($partnerno: String!, $rolename: String!) {
  allCompaniesByPartnerNumber(partnerno: $partnerno, rolename: $rolename) {
    companyId
    companyCode
    companyName
    roleId
    roleName
    systemId
    systemName
    systemCode
  }
}
    `;

/**
 * __useAllCompaniesByPartnerNumberQuery__
 *
 * To run a query within a React component, call `useAllCompaniesByPartnerNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesByPartnerNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesByPartnerNumberQuery({
 *   variables: {
 *      partnerno: // value for 'partnerno'
 *      rolename: // value for 'rolename'
 *   },
 * });
 */
export function useAllCompaniesByPartnerNumberQuery(baseOptions: Apollo.QueryHookOptions<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables> & ({ variables: AllCompaniesByPartnerNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables>(AllCompaniesByPartnerNumberDocument, options);
      }
export function useAllCompaniesByPartnerNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables>(AllCompaniesByPartnerNumberDocument, options);
        }
export function useAllCompaniesByPartnerNumberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables>(AllCompaniesByPartnerNumberDocument, options);
        }
export type AllCompaniesByPartnerNumberQueryHookResult = ReturnType<typeof useAllCompaniesByPartnerNumberQuery>;
export type AllCompaniesByPartnerNumberLazyQueryHookResult = ReturnType<typeof useAllCompaniesByPartnerNumberLazyQuery>;
export type AllCompaniesByPartnerNumberSuspenseQueryHookResult = ReturnType<typeof useAllCompaniesByPartnerNumberSuspenseQuery>;
export type AllCompaniesByPartnerNumberQueryResult = Apollo.QueryResult<AllCompaniesByPartnerNumberQuery, AllCompaniesByPartnerNumberQueryVariables>;
export const MutateAssignedUserRightsDocument = gql`
    mutation mutateAssignedUserRights($userId: Float!, $userRights: [UserRightsInput!]!) {
  mutateAssignedUserRights(userId: $userId, userRights: $userRights) {
    rowno
    userId
    userName
    partnerId
    partnerNo
    partnerName
    roleName
    roleId
    processCodeId
    processCode
    processCodeName
    companyLinkExists
    companyCode
    companyName
    companyId
    systemCompanyId
    systemId
    systemCode
    systemName
    companyPartnerId
    assigned
  }
}
    `;
export type MutateAssignedUserRightsMutationFn = Apollo.MutationFunction<MutateAssignedUserRightsMutation, MutateAssignedUserRightsMutationVariables>;

/**
 * __useMutateAssignedUserRightsMutation__
 *
 * To run a mutation, you first call `useMutateAssignedUserRightsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateAssignedUserRightsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateAssignedUserRightsMutation, { data, loading, error }] = useMutateAssignedUserRightsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userRights: // value for 'userRights'
 *   },
 * });
 */
export function useMutateAssignedUserRightsMutation(baseOptions?: Apollo.MutationHookOptions<MutateAssignedUserRightsMutation, MutateAssignedUserRightsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateAssignedUserRightsMutation, MutateAssignedUserRightsMutationVariables>(MutateAssignedUserRightsDocument, options);
      }
export type MutateAssignedUserRightsMutationHookResult = ReturnType<typeof useMutateAssignedUserRightsMutation>;
export type MutateAssignedUserRightsMutationResult = Apollo.MutationResult<MutateAssignedUserRightsMutation>;
export type MutateAssignedUserRightsMutationOptions = Apollo.BaseMutationOptions<MutateAssignedUserRightsMutation, MutateAssignedUserRightsMutationVariables>;
export const PreferencesGetApiDocument = gql`
    query PreferencesGetAPI($preference: String!) {
  userPreferences(preference: $preference) {
    id
    code
    description
  }
}
    `;

/**
 * __usePreferencesGetApiQuery__
 *
 * To run a query within a React component, call `usePreferencesGetApiQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesGetApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesGetApiQuery({
 *   variables: {
 *      preference: // value for 'preference'
 *   },
 * });
 */
export function usePreferencesGetApiQuery(baseOptions: Apollo.QueryHookOptions<PreferencesGetApiQuery, PreferencesGetApiQueryVariables> & ({ variables: PreferencesGetApiQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreferencesGetApiQuery, PreferencesGetApiQueryVariables>(PreferencesGetApiDocument, options);
      }
export function usePreferencesGetApiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreferencesGetApiQuery, PreferencesGetApiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreferencesGetApiQuery, PreferencesGetApiQueryVariables>(PreferencesGetApiDocument, options);
        }
export function usePreferencesGetApiSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreferencesGetApiQuery, PreferencesGetApiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreferencesGetApiQuery, PreferencesGetApiQueryVariables>(PreferencesGetApiDocument, options);
        }
export type PreferencesGetApiQueryHookResult = ReturnType<typeof usePreferencesGetApiQuery>;
export type PreferencesGetApiLazyQueryHookResult = ReturnType<typeof usePreferencesGetApiLazyQuery>;
export type PreferencesGetApiSuspenseQueryHookResult = ReturnType<typeof usePreferencesGetApiSuspenseQuery>;
export type PreferencesGetApiQueryResult = Apollo.QueryResult<PreferencesGetApiQuery, PreferencesGetApiQueryVariables>;
export const GetAllUserRightsDocument = gql`
    query getAllUserRights($userId: Float!) {
  getAllUserRights(userId: $userId) {
    rowno
    userId
    userName
    partnerId
    partnerNo
    partnerName
    roleName
    roleId
    processCodeId
    processCode
    processCodeName
    companyLinkExists
    companyCode
    companyName
    companyId
    systemCompanyId
    systemId
    systemCode
    systemName
    companyPartnerId
    assigned
    insertTime
    insertUser
  }
}
    `;

/**
 * __useGetAllUserRightsQuery__
 *
 * To run a query within a React component, call `useGetAllUserRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserRightsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllUserRightsQuery(baseOptions: Apollo.QueryHookOptions<GetAllUserRightsQuery, GetAllUserRightsQueryVariables> & ({ variables: GetAllUserRightsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUserRightsQuery, GetAllUserRightsQueryVariables>(GetAllUserRightsDocument, options);
      }
export function useGetAllUserRightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUserRightsQuery, GetAllUserRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUserRightsQuery, GetAllUserRightsQueryVariables>(GetAllUserRightsDocument, options);
        }
export function useGetAllUserRightsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllUserRightsQuery, GetAllUserRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUserRightsQuery, GetAllUserRightsQueryVariables>(GetAllUserRightsDocument, options);
        }
export type GetAllUserRightsQueryHookResult = ReturnType<typeof useGetAllUserRightsQuery>;
export type GetAllUserRightsLazyQueryHookResult = ReturnType<typeof useGetAllUserRightsLazyQuery>;
export type GetAllUserRightsSuspenseQueryHookResult = ReturnType<typeof useGetAllUserRightsSuspenseQuery>;
export type GetAllUserRightsQueryResult = Apollo.QueryResult<GetAllUserRightsQuery, GetAllUserRightsQueryVariables>;
export const ValidateUserDocument = gql`
    query validateUser {
  validateUser {
    portalUser {
      user_id
      role_id
      username
      firstname
      lastname
      email
      roleName
      realm
      uid
      departmentnumber
      roles
    }
    userPreferences {
      language
      decimalSeparator
      thousandSeparator
      dateFormat
      userSettingsId
    }
    success
    errorCode
    errorDescription
  }
}
    `;

/**
 * __useValidateUserQuery__
 *
 * To run a query within a React component, call `useValidateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidateUserQuery(baseOptions?: Apollo.QueryHookOptions<ValidateUserQuery, ValidateUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateUserQuery, ValidateUserQueryVariables>(ValidateUserDocument, options);
      }
export function useValidateUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateUserQuery, ValidateUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateUserQuery, ValidateUserQueryVariables>(ValidateUserDocument, options);
        }
export function useValidateUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateUserQuery, ValidateUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateUserQuery, ValidateUserQueryVariables>(ValidateUserDocument, options);
        }
export type ValidateUserQueryHookResult = ReturnType<typeof useValidateUserQuery>;
export type ValidateUserLazyQueryHookResult = ReturnType<typeof useValidateUserLazyQuery>;
export type ValidateUserSuspenseQueryHookResult = ReturnType<typeof useValidateUserSuspenseQuery>;
export type ValidateUserQueryResult = Apollo.QueryResult<ValidateUserQuery, ValidateUserQueryVariables>;