import client from "../../../config/GraphQLApolloClient";
import {
  GET_COUNTRY_LIST,
  GET_CURRENCY_LIST,
  GET_REGION_LIST,
  GET_TAX_REFERENCES,
  GET_UNIT_OF_MEASURES
} from "../queries";
import {getCountryList} from "../__generated__/getCountryList";
import {getCurrencyList} from "../__generated__/getCurrencyList";
import {getRegionList} from "../__generated__/getRegionList";
import {UnitOfMeasure} from "./model/UnitOfMeasure";
import {TaxLawReference} from "./model/TaxLawReference";
import {ExemptionType} from "./model/ExemptionType";
import {TaxLawReferenceAPI} from "../__generated__/TaxLawReferenceAPI";
// import {expandTagDescription} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {UnitOfMeasureAPI} from "../__generated__/UnitOfMeasureAPI";

export const getCountryListData = () => {
  // get the partner VAT information
  return new Promise<getCountryList>((resolve, reject) => {
    client.query<getCountryList>({
      query: GET_COUNTRY_LIST,
      fetchPolicy: "cache-first"
    }).then((response) => {
      const finalResponse: getCountryList = { getCountryList: response?.data.getCountryList };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const getRegionListData = () => {
  // get the Region List
  return new Promise<getRegionList>((resolve, reject) => {
    client.query<getRegionList>({
      query: GET_REGION_LIST,
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getRegionList = { getRegionList: response?.data.getRegionList };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const getCurrencyListData = () => {
  // get the Currency List
  return new Promise<getCurrencyList>((resolve, reject) => {
    client.query<getCurrencyList>({
      query: GET_CURRENCY_LIST,
      fetchPolicy: "cache-first"
    }).then((response) => {
      const finalResponse: getCurrencyList = { getCurrencyList: response?.data.getCurrencyList };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export async function getUnitOfMeasureTypes():Promise<UnitOfMeasure[]> {
  return new Promise<UnitOfMeasure[]>((resolve,reject)=> {
    client.query<UnitOfMeasureAPI>( {
      query:GET_UNIT_OF_MEASURES,
      fetchPolicy:"cache-first"
    }).then(result=> {
      const dtoList:UnitOfMeasure[] =
          result.data.getAllUnitOfMeasureValues.map(item=>(
              {
                id:item.id,
                decimalPlaces:item.decimalPlaces,
                allowDecimal:item.unitOfMeasureAllowDecimal,
                description:item.unitOfMeasureSymbol,
              symbol:item.unitOfMeasureSymbol}));

      resolve(dtoList)
    })
        .catch(error=> {
          console.error(error);
          reject(error);
        })
  });
};

export async function getTaxLawReferences():Promise<TaxLawReference[]> {
  return new Promise<TaxLawReference[]>((resolve, reject) => {
    client.query<TaxLawReferenceAPI>({
      query: GET_TAX_REFERENCES,
      fetchPolicy: "cache-first"
    }).then((response) => {
      const finalResponse = response.data.getTaxLawReferences.map(reference=> ({id:reference.id, description:reference.taxLawReference,code:reference.taxTypeCode,phraseId:reference.phraseId}));
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
};

export async function getExemptionTypes(): Promise<ExemptionType[]> {
  return new Promise<ExemptionType[]>((resolve, reject) => {
    client.query<TaxLawReferenceAPI>({
      query: GET_TAX_REFERENCES,
      fetchPolicy: "cache-first"
    }).then((response) => {
      const finalResponse = response.data.getTaxExemptions.map(reference=> ({id:reference.id, description:reference.taxExemptionType,taxLawReferenceId:reference.taxLawReferenceId, phraseId:reference.phraseId }));
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
}