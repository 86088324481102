// @ts-ignore
import React, { useEffect, useState } from "react";
// @ts-ignore
import { useIntl } from "react-intl";
import { EditOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import {Card, Col, ConfigProvider, Form, Input, notification, Row, Select, Spin, Tabs, Tooltip, Typography} from "antd";
import { AgGridReact } from "ag-grid-react";
import "./SurveySupplierDocumentType.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  DEFAULT_COL_DEF,
  getDefinitionFormatChannelHelp,
  getFormats,
  SURVEY_CUSTOMER_DOCUMENT_PARTNER_TABLE_HEADERS,
  SURVEY_DOCUMENT_CHANNEL_TABLE_HEADERS
} from "./documentTypeUtils";
import { GridApi } from "ag-grid-community";
import Paragraph from "antd/es/typography/Paragraph";
import { PARTNER_TYPE, SELECTED_COMPANY_CODE } from "../../../constants";
import {
  getDefaultChannelData,
  getProcessName,
  getSurveyChannelBySurveyId,
  insertSurveyChannels
} from "./DocumentTypeService";
import Link from "antd/lib/typography/Link";
import { getDefaultChannelFormat_getDefaultChannelFormat } from "./__generated__/getDefaultChannelFormat";
import { getAgGridLocalization } from "../../info-portal/components/grid/GridUtil";
import {StateContainer} from "../utilities/StateContainer";
import { showErrorWithHeaderNotification } from "../../Notification";
import { useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";

type ISurveySuppliersDocumentTypeProps = {
  next?: any;
  prev?: any;
  surveyMasterData?: any;
  channelData?: any;
  onStatusUpdate?: any;
  stateContainer?: StateContainer;

}
const SurveyCustomerDocumentType: React.FunctionComponent<ISurveySuppliersDocumentTypeProps> = (props) => {
  const { TabPane } = Tabs;
  const intl = useIntl();
  const [gridApi, setGridApi] = useState<GridApi>(undefined);
  const [loading, isLoading] = useState(false);
  const [channelOptions, setChannelOptions] = useState([]);
  const [formatOptions, setFormatOptions] = useState([]);
  const [channelFormatOptionData, setChannelFormatOptionData] = useState<getDefaultChannelFormat_getDefaultChannelFormat>();

  const [selectedPartner, setSelectedPartner] = useState(undefined);
  const [channelData, setChannelData] = useState(undefined);
  const [showEditPanel, isShowEditPanel] = useState(false);
  const [updatedFormat, setUpdatedFormat] = useState(undefined);
  const [updatedChannel, setUpdatedChannel] = useState(undefined);
  const [selectedProvider, setSelectedProvider] = useState(undefined);
  const [surveySummaryMasterData, setSurveySummaryMasterData] = useState(undefined);
  const [form] = Form.useForm();

  
 const surveyState = useAppSelector((state) => state.survey);

 const isViewMode = () => {
   return surveyState.survey.surveyMode === MODE.VIEW ? true : false
 }

 const isOverViewMode = () => {
   return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
 }

  const warningMessage = intl.formatMessage({
    id: "survey-eDocument-supplier-defaultAccept-message",
    defaultMessage: "You have decided to accept the BMW default settings for Format and Channels of the Process Groups on the eDocument Types for the selected partner/s."
  });
  const headerParagraphLine1 = intl.formatMessage({
    id: "survey-eDocument-customer-headerText-line1",
  });
  const headerParagraphLine2 = intl.formatMessage({
    id: "survey-eDocument-customer-headerText-line2",
  });
  const headerParagraphLine3 = intl.formatMessage({
    id: "survey-eDocument-customer-headerText-line3",
  });

  const acceptDefaultTooltipTitle = intl.formatMessage({
    id: "survey-eDocument-customer-grid-acceptDefault-tooltip-title",
    defaultMessage: "Accept Default"
  });

  const eDocumentTitle = intl.formatMessage({
    id: "survey-eDocument-customer-title",
    defaultMessage: "Document Types"
  });

  const editMessage = intl.formatMessage({
    id: "survey-eDocument-customer-edit-messages",
  });


  const editDefaultTooltipTitle = intl.formatMessage({
    id: "survey-eDocument-customer-grid-editChannel-tooltip-title",
    defaultMessage: "Edit All"
  });

  const saveDefaultTooltipTitle = intl.formatMessage({
    id: "survey-eDocument-customer-grid-saveChannel-tooltip-title",
    defaultMessage: "Save"
  });

  const definitionsFormatChannel = intl.formatMessage({
    id: "guidelines-and-testfiles",
    defaultMessage: "Guidelines and testfiles"
  });

  const updatedChannelLabel = intl.formatMessage({
    id: "survey-eDocument-customer-updatedChannel-label",
    defaultMessage: "Updated Channel"
  });

  const updatedChannelPlaceholder = intl.formatMessage({
    id: "survey-eDocument-customer-updatedChannel-placeholder",
    defaultMessage: "Select"
  });

  const updatedFormatLabel = intl.formatMessage({
    id: "survey-eDocument-customer-updatedFormat-label",
    defaultMessage: "Updated Format"
  });

  const updatedFormatPlaceholder = intl.formatMessage({
    id: "survey-eDocument-customer-updatedFormat-placeholder",
    defaultMessage: "Select"
  });

  useEffect(() => {
    const surveySummaryMasterDataArray = [];
    props.surveyMasterData.forEach(item => {
      surveySummaryMasterDataArray.push({
        partnerNo: item.surveySummaryRecord.partnerNo,
        partnerName: item.surveySummaryRecord.partnerName,
        companyCode: item.surveySummaryRecord.companyCode
      });
    });

    setSurveySummaryMasterData(surveySummaryMasterDataArray);

    if (channelData === undefined) {
      isLoading(true);
      Promise.resolve(getSurveyChannelBySurveyId(props.surveyMasterData[0].surveySummaryRecord.surveyId,
        localStorage.getItem(PARTNER_TYPE), localStorage.getItem(SELECTED_COMPANY_CODE), intl)).then(customerChannels => {
        if (Array.isArray(customerChannels) && customerChannels.length > 0) {
          setChannelData(customerChannels);
          isLoading(false);
        } else {
          Promise.resolve(getDefaultChannelData(localStorage.getItem(PARTNER_TYPE), props.surveyMasterData[0].surveySummaryRecord.companyCode))
            .then(channels => {
              Promise.resolve(getProcessName(channels, intl)).then(customerChannels => {
                setChannelData(customerChannels);
                isLoading(false);
              });
            });
        }
      });

    }


  }, [channelData]);


  const onDocumentCustomerTypeGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };



  const editChannel = () => {
    isLoading(false);
    const formats = [];
    const selectedProcessCode = channelData.filter(item => item.processCode !== "INC" && item.processCode !== "PAD");
    if (selectedProcessCode.length > 0) {
      Promise.resolve(getFormats(localStorage.getItem(PARTNER_TYPE),
        props.surveyMasterData[0].surveySummaryRecord.companyCode, selectedProcessCode[0].processCode, "OUT"))
        .then(returnedFormats => {
          returnedFormats.format.map((item) => {
            formats.push(item.formatName);
          });
          setFormatOptions(formats);
          setChannelFormatOptionData(returnedFormats);
          isLoading(false);
          isShowEditPanel(true);
        });
    }
  };


  const saveChannel = () => {
    if (!updatedFormat || !updatedChannel) {
      showErrorWithHeaderNotification(intl.formatMessage({
          id: "survey-eDocument-customer-channel-not-selected-error",
          defaultMessage: "Fail"
        }),
        intl.formatMessage({
          id: "survey-eDocument-customer-channel-not-selected-error-message",
          defaultMessage: "Please select Format and Channels to update"
        }));
    } else {
      props.surveyMasterData.forEach((surveySummaryItem, index) => {
        const inputData = [];
        isLoading(true);
        if (Array.isArray(channelData)) {
          channelData.forEach(item => {
            inputData.push({
              surveyId: Number(surveySummaryItem.surveySummaryRecord.surveyId),
              surveyChannelId: item.surveyChannelId,
              processCodeId: item.processCodeId,
              documentType: item.documentType,
              format: updatedFormat,
              channel: updatedChannel,
              provider: selectedProvider,
              channelDefault: true
            });

          });
        }
        Promise.resolve(insertSurveyChannels(props.surveyMasterData[index].surveySummaryRecord.includedSurveyIds, inputData)).then(channelData => {
          const insertedData = [];
          if (Array.isArray(channelData)) {
            channelData.forEach(item => {
              const channelRow = { ...item };
              channelRow.updatedChannel = item.channel;
              channelRow.updatedFormat = item.format;
              channelRow.channel = item.channel;
              channelRow.format = item.format;
              insertedData.push(channelRow);
            });

            form.setFieldsValue({
              format: insertedData?.length > 0 ? insertedData[0].format : "PDF",
              channel: insertedData?.length > 0 ? insertedData[0].channel : "PORTAL"
            });
          }
          setChannelData(insertedData);
          isLoading(false);
          notification.info({
            message: intl.formatMessage({
              id: "survey-eDocument-supplier-success-notification-header",
              defaultMessage: "SUCCESSFULLY"
            }),
            description:
              intl.formatMessage({
                id: "survey-eDocument-customer-saved-channel-successfully",
                defaultMessage: "Format and Channels successfully saved"
              })
          });
          props.onStatusUpdate();
        }).catch(error => {
          isLoading(false);
          notification.info({
            message: intl.formatMessage({
              id: "survey-eDocument-supplier-error-notification-header",
              defaultMessage: "ERROR"
            }),
            description:
              intl.formatMessage({
                id: "survey-eDocument-customer-saved-channel-error",
                defaultMessage: error
              })
          });
        });

      });
    }

  };

  const onChannelChange = (value) => {
    setUpdatedChannel(value);
    channelFormatOptionData.format
      .filter(item => item.formatName === updatedFormat)
      .map(filtItem => filtItem.channel
        .filter(channelItem => channelItem.channelName === value)
        .map(providerItem => {
          setSelectedProvider(providerItem.provider);
        }));
  };
  const onFormatChange = (value) => {
    const channels = [];
      form.setFieldsValue({ updatedChannel: undefined });
      setUpdatedChannel(undefined);
    channelFormatOptionData.format.filter(item => item.formatName === value).map(filtItem => filtItem.channel.forEach(channelData => {
      channels.push(channelData.channelName);
    }));
    setUpdatedFormat(value);
    setChannelOptions(channels);
  };


  return (
    <>
        <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
      <Spin spinning={loading} size={"large"}>

        <Card title={eDocumentTitle} size={"small"} className={"ml-10"}>
          <Typography>
            <Paragraph>
              <Row>
                {headerParagraphLine1}
              </Row>
              <Row>
                {headerParagraphLine2}
              </Row>
              <Row>
                {headerParagraphLine3}
              </Row>
            </Paragraph>
          </Typography>

          <Row gutter={24}>
            <Col span={24} className="mb5">
              <QuestionCircleOutlined style={{ fontSize: "20px", color: "#444444" }} className={"mr10"} />
              <Link href={getDefinitionFormatChannelHelp()} target="_blank">
                {definitionsFormatChannel}
              </Link>
            </Col>
          </Row>
          <Row gutter={24} justify={"start"} hidden={isViewMode() || isOverViewMode()}>
            <Col className="mb5">
              {/*        <Tooltip title={acceptDefaultTooltipTitle} key={"acceptKey"}>
                <Popconfirm placement="top" title={warningMessage} onConfirm={accept} okText="Yes" cancelText="No">
                  <CheckOutlined style={{ fontSize: "20px", color: "#4a576d", cursor: "pointer" }}
                                 className={"mr10"} />
                </Popconfirm>
              </Tooltip>*/}
              <Tooltip title={editDefaultTooltipTitle} key={"EditChannel"}>
                <EditOutlined style={{ fontSize: "20px", color: "#024051", cursor: "pointer" }}
                              onClick={editChannel} />
              </Tooltip>
              <Tooltip title={saveDefaultTooltipTitle} key={"acceptKey"}>
                <SaveOutlined style={{ fontSize: "20px", color: "#024051", cursor: "pointer" }} className={"ml10"}
                              onClick={saveChannel} />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} className="mb5">
              <div style={{ flex: "auto", height: "200px", paddingTop: "0px" }}>
                <AgGridReact
                  defaultColDef={{
                    enableValue: true,
                    sortable: true,
                    resizable: true
                  }}
                  rowData={surveySummaryMasterData}
                  columnDefs={SURVEY_CUSTOMER_DOCUMENT_PARTNER_TABLE_HEADERS(intl)}
                  localeText={getAgGridLocalization(intl)}
                  sideBar={false}
                  rowClass="agGridRows"
                  suppressMenuHide={true}
                  debug={false}
                  pagination={true}
                  paginationPageSize={5}

                />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} className="mb5">
              <div style={{ flex: "auto", height: "350px", paddingTop: "10px" }}>
                <AgGridReact
                  defaultColDef={DEFAULT_COL_DEF}
                  rowData={channelData}
                  columnDefs={SURVEY_DOCUMENT_CHANNEL_TABLE_HEADERS(intl)}
                  sideBar={false}
                  rowClass="agGridRows"
                  suppressMenuHide={true}
                  debug={false}
                  pagination={true}
                  localeText={getAgGridLocalization(intl)}
                  paginationPageSize={10}
                  onGridReady={onDocumentCustomerTypeGridReady}
                />
              </div>
            </Col>
          </Row>
          {showEditPanel &&
          <Card size={"small"}>
            <Typography>
              <Paragraph>
                {editMessage}
              </Paragraph>
            </Typography>
            <Form size={"middle"} layout={"vertical"} form={form} initialValues={{
              format: channelData?.length > 0 ? channelData[0].format : "PDF",
              channel: channelData?.length > 0 ? channelData[0].channel : "PORTAL"
            }}
                  onFinish={() => saveChannel}>
              <Row gutter={24}>
                <Col span={3}>
                  <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-format'})}</label>
                  <Form.Item name={"format"} key={"formatKey"}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-channel'})}</label>
                  <Form.Item name={"channel"} key={"channelKey"}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <label>{updatedFormatLabel}</label>
                  <Form.Item name={"updatedFormat"} key={"updatedCustomerFormatKey"}   >
                    <Select placeholder={updatedFormatPlaceholder} allowClear={true}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                            }
                            optionFilterProp="children"
                            showSearch={true}
                            data-testid="updatedFormat"
                            onChange={onFormatChange}>
                      {
                        formatOptions.map((item, index) => (
                          <Select.Option key={`${item}_${index}`} value={item} data-testid={item}>
                            {item}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                </Col>
                <Col span={3}>

                  <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-updated-channels'})}</label>
                  <Form.Item name={"updatedChannel"} key={"UpdatedCustomerFormatKey"} rules={[{ required: true, message: 'Please select document format!' }]}>
                    <Select placeholder={updatedChannelPlaceholder} allowClear={true}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                            }
                            optionFilterProp="children"
                            showSearch={true}
                            data-testid="updatedFormat"

                            onChange={onChannelChange}>
                      {channelOptions &&
                      channelOptions.map((item, index) => (
                        <Select.Option key={`${item}_${index}`} value={item} data-testid={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          }
        </Card>
      </Spin>
        </ConfigProvider>

    </>
  );
};

export default SurveyCustomerDocumentType;