import React, { FunctionComponent, useEffect, useState } from "react";
import { Alert, Col, ConfigProvider, Form, Modal, Row, Spin, Tabs, Typography } from "antd";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { PageContainer } from "@ant-design/pro-layout";
import styles from "../../../styles/scss/portal.scss";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import enUS from "antd/lib/locale/en_US";
import { getI18n, isExternalUser } from "../../../utils/Utils";
import DraftDocumentSearchForm from "./DraftDocumentSearchForm";
import { FailureDownloadDocsResp, IAppComponentProps } from "../../../components";
import { CustomRender } from "../../../components/CustomRender";
import dayjs from 'dayjs';
import { getDraftDocument } from "./DraftDocumentService";
import { MYDOCS_TABLE_HEADERS_DRAFT } from "./draftDocumentUtils";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import { isDraftDocumentLoading, setTotalDraftDocument } from "../IStoredDraftDocumentLoadingSlice";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import deDe from "antd/lib/locale/de_DE";
import {DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID, ECAP_USER_ENTITLEMENT_BY_USERID} from "../../../main/queries";
import { getEcapUserEntitlementResultByUserId } from "../../../main/__generated__/getEcapUserEntitlementResultByUserId";
import { getAgGridLocalization } from "../../info-portal/components/grid/GridUtil";
import utc from 'dayjs/plugin/utc';
import {
  getDocumentUserEntitlementResultByUserId
} from "../../../main/__generated__/getDocumentUserEntitlementResultByUserId";

dayjs.extend(utc);

const DraftDocuments: FunctionComponent<IAppComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentUser = props.currentUser;
  const [form] = Form.useForm();
  const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
  const [docData, setDocData] = useState(undefined);
  const [docCount, setDocCount] = useState(0);
  const [formDataValues, setFormDataValues] = useState(undefined);
  const [failureDocuments, setFailureDocuments] = useState<FailureDownloadDocsResp[]>(undefined);
  const [partnerNos, setPartnerNos] = useState<string[]>(undefined);


  const { TabPane } = Tabs;
  const sizes: React.CSSProperties = {
    height: "100%",
    width: "98%"
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  const intl = useIntl();

  const { loading, total } = useAppSelector((state) => state.draftDocumentLoading);
  const [getUserEntitlement, {}] = useLazyQuery<getDocumentUserEntitlementResultByUserId>(
      DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID,
      {
        variables: {
          userId: props.currentUser?.uid,
          entitlement: 'display_dd@ecap',
          partnerName: String,
          authentication: localStorage.getItem('id_token'),
        },
        fetchPolicy: 'network-only',
      },
  );


  const onDocGridReady = (params) => {
    setDocGridApi(params.api);
  };

  const onExport = () => {

    const selectedRows = docGridApi.getSelectedRows();
    console.dir(selectedRows);

    if (selectedRows.length === 0) {
      //console.log("Error")
      Modal.warning({
        title: intl.formatMessage({
          id: "Warning-message",
          defaultMessage: "Export Document(s)"
        }),
        content: intl.formatMessage({
          id: "Warning-export-message",
          defaultMessage: "You have not selected any items to be exported. Please select an item first."
        })
      });
    } else {
      docGridApi.exportDataAsExcel({
        onlySelectedAllPages: false,
        onlySelected: true,
        fileName: "Draft_Document_Export"
      });
    }
  };

  const onSearch = (values) => {
    dispatch(isDraftDocumentLoading(true));
    setFormDataValues(values);

    const draftListInput = {

      documentDateFrom: values.documentDateFrom ? dayjs(values.documentDateFrom).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      documentDateTo: values.documentDateTo ? dayjs(values.documentDateTo).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      //21427181
      bmwCompanyId: values.bmwCompanyId ? parseInt(values.bmwCompanyId) : undefined,
      documentNumbers: values.documentNumbers,
      documentTypeId: values.documentTypeId ? parseInt(values.documentTypeId) : undefined,
      downloaded: values.downloaded ? parseInt(values.downloaded) : undefined,
      formatId: values.formatId ? parseInt(values.formatId) : undefined,
      partnerId: values.partnerId ? parseInt(values.partnerId) : undefined,
      processCodeId: values.processCodeId ? parseInt(values.processCodeId) : undefined,
      statusId: values.statusId ? parseInt(values.statusId) : undefined,
      myDocuments: values.myDocuments,
      partnerNoList: values.partnerNoList ? values.partnerNoList : isExternalUser(props.distinctEntitlements) && !values.partnerNo ? partnerNos : undefined
    };
    localStorage.setItem("draftListInput", JSON.stringify(draftListInput));
    Promise.resolve(getDraftDocument(currentUser, draftListInput, intl))
      .then((data) => {
        setDocCount(data.DraftDocumentList.length);
        dispatch(setTotalDraftDocument(data?.DraftDocumentList.length));
        setDocData(data.DraftDocumentList);
        dispatch(isDraftDocumentLoading(false));
      })
      .catch((err) => {
        dispatch(isDraftDocumentLoading(false));
        console.error(err);
      });
  };

  useEffect(() => {
    const draftDocumentInput = {
      documentDateFrom: dayjs().startOf("d").format("YYYY-MM-DD"),
      documentDateTo: dayjs().endOf("d").format("YYYY-MM-DD")
    };
    if (isExternalUser(props.distinctEntitlements)) {
      dispatch(isDraftDocumentLoading(true));
      const partnerNos: string[] = [];
      getUserEntitlement().then(response => {
        return new Promise<string[]>((resolve) => {
          const userEntitlement = response.data?.getDocumentUserEntitlementResultByUserId;
          userEntitlement.partners.map(item => {
            partnerNos.push(item.partnerNo);
          });
          setPartnerNos(partnerNos);
          resolve(partnerNos);
        });
      })
        .then(partnerNoList => {
          onSearch({ ...draftDocumentInput, partnerNoList });
        });
    } else {
      onSearch(draftDocumentInput);
    }
  }, [props.currentUser]);

  return (
    <>
      <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
        <PageContainer content={""} extraContent={""} className={styles.users} style={{ background: "white" }}
                       title={false}>

          <Spin spinning={loading} size={"large"}>

            <div style={{ ...sizes, minHeight: "200px" }} className="pl15 ag-theme-balham">
              <Col md={24} sm={12} xs={24}>
                <Tabs defaultActiveKey={"1"} className="users-tabs">
                  <TabPane tab={getI18n("Document-title-draft", "Draft Documents", intl)} key="1">
                    <Typography.Title level={5} className={"mb10"}>
                      {getI18n("Document-title-draft-message", "", intl)}
                    </Typography.Title>
                    <DraftDocumentSearchForm onExport={onExport} onSearch={onSearch} currentUser={currentUser}
                                             intl={props.intl} distinctEntitlements={props.distinctEntitlements}
                                             count={total} />

                    {failureDocuments &&
                    <Row gutter={24}>
                      <Col span={24} className="mb5 mt5">
                        {failureDocuments.map((x, i) =>
                          <Alert key={i} message={`${x.errorMessage}`} type="error" showIcon={true} closable={true} />)}
                      </Col>
                    </Row>}

                    <Col md={24} sm={12} xs={24}>
                      <Tabs defaultActiveKey={"1"}>
                        <TabPane tab={getI18n("Document-title-draft", "Draft Documents", intl)} key="1">

                          <div style={{ flex: "auto", height: "500px" }}>
                            <AgGridReact
                              defaultColDef={{
                                enableValue: true,
                                sortable: true,
                                resizable: true

                              }}
                              api={docGridApi}
                              columnDefs={MYDOCS_TABLE_HEADERS_DRAFT(intl, props.distinctEntitlements, props.currentUser)}
                              rowData={docData}
                              sideBar={false}
                              rowClass="agGridRows"
                              localeText={getAgGridLocalization(intl)}
                              suppressMenuHide={true}
                              debug={true}
                              onGridSizeChanged={() => docGridApi.sizeColumnsToFit()}
                              onGridReady={onDocGridReady}
                              rowSelection={"multiple"}
                              pagination={true}
                              paginationPageSize={15}
                              suppressRowClickSelection={true}
                              enableBrowserTooltips={true}
                              data-testid="documents-test-id" />
                          </div>
                        </TabPane>
                      </Tabs>
                    </Col>
                  </TabPane>
                </Tabs>
              </Col>
            </div>
          </Spin>


        </PageContainer>
      </ConfigProvider>

    </>
  );
};
export default DraftDocuments;

function parseBoolean(myDocuments: any) {
  throw new Error("Function not implemented.");
}

