import { Alert, Col, Row, Spin } from "antd";
import React from "react";
import styles from "../styles/scss/portal.scss";

interface IProps {
  userLoading?: any;
  errorMessage?: any;
}

const PortalLoading: React.FunctionComponent<IProps> = ({ userLoading, errorMessage }: IProps) => {

  return (
    <Row gutter={16}>
      <Col xs={24} style={{ height: "100vh", textAlign: "center", paddingTop: "5vh" }}>
        <div style={{ transform: "translateY(50%)" }} className={styles.users}>
          <Spin size={"large"} spinning={userLoading} tip="Loading..." style={{ color: "#024051" }}>
            <Row gutter={16}>
              <Col>
                <Row gutter={16}>
                  <Col className="gutter-row" style={{ marginLeft: "5px" }}>
                    <div
                      style={{ textAlign: "center", width: "1000px", height: "300px", marginLeft: "5px" }}>
                      <div className="title-header-card-text">
                        {errorMessage &&
                        <Alert showIcon={true} message={"eDocument Portal Error"} description={errorMessage}
                               type="error" />}
                      </div>
                      {userLoading &&
                      <div className="title-header-card-text">
                        PORTAL LOADING .....
                      </div>
                      }

                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

export default PortalLoading;