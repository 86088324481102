import client from "../config/GraphQLApolloClient";
import {gql} from "@apollo/client";
import {PortalUser, randomInt} from "../components";
import dayjs from 'dayjs';
import {setAuthority} from "../utils/authority";
import {VALIDATE_USER} from "../pages/users/queries";
import {Buffer} from "buffer";

const externalRealm = "internetb2x";
const internalRealm = "intranetb2x";
const internalReferrer = "bmwgroup.net";
const externalReferrerB2x = "bmw.com";
const externalReferrerSGate = "bmwgroup.com";

const storageKeyPortalParam = "portalParam";
const storageKeyAuthDomainParam = "authDomainParam";
const storageKeyRealmParam = "realmParam";
const urlPortalParam = "portal";
const urlAuthDomainParam = "authdomain";
const urlRealmParam = "realm";

let defaultRealm = externalRealm;

const QUERY_GET_WEBEAM_CONFIG = gql`
    query OAuth_getWebEamConfiguration {
        OAuth_getWebEamConfiguration {
            url,
            externalUrl,
            defaultRealm,
            enabled
        }
    }
`;

export const QUERY_GET_WEBEAM_SESSION_LOGOUT_URL = gql`
    query OAuth_getWebEamSessionLogoutUrl($tokenId:String!, $realm:String!) {
        OAuth_getWebEamSessionLogoutUrl(tokenId : $tokenId, realm : $realm)
    }
`;

// const QUERY_GET_WEBEAM_USER = gql`
//     query OAuth_getWebEamUserInfo($token: String!) {
//         OAuth_getWebEamUserInfo(token: $token) {
//             suppliernumber
//             suppliername
//             given_name
//             family_name
//             name
//             departmentnumber
//             inumber
//             email
//             sub
//             auth_level
//         }
//     }
// `;

const QUERY_GET_PORTAL_USER_EMAIL = gql`
    query PortalUser_getPortalUserInfoByEmail($email: String!) {
        PortalUser_getPortalUserInfoByEmail(email: $email) {
            user_id
            username
            firstname
            lastname
            email
            roleName

        }
    }
`;

export function setPortalParam(portal: string) {
    localStorage.setItem(storageKeyPortalParam,portal);
}
export function getPortalParam() {
    return localStorage.getItem(storageKeyPortalParam);
}
export function setRealmParam(realm: string){
    localStorage.setItem(storageKeyRealmParam,realm);
}
export function getRealmParam(){
    return localStorage.getItem(storageKeyRealmParam);
}
export function setAuthDomainParam(authDomain: string){
    localStorage.setItem(storageKeyAuthDomainParam,authDomain);
}
export function getAuthDomainParam(){
    return localStorage.getItem(storageKeyAuthDomainParam);
}

export function getLastDocumentReferrer() {
    return localStorage.getItem("lastReferrer");
}

export const isInternalReferrer = (referrer: string) => {
    if (referrer != null) {
        return referrer.includes(internalReferrer);
    }
    return false;
}

export const isExternalReferrer = (referrer: string) => {
    if (referrer != null) {
        return referrer.includes(externalReferrerB2x) || referrer.includes(externalReferrerSGate);
    }
    return false;
}

export const isValidReferrer = (referrer: string) => {
    return isInternalReferrer(referrer) || isExternalReferrer(referrer);
}

export function getParameterByName(name: string, url = window.location.href) {
    const paramName = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function setLastDocumentReferrer() {
    console.log(`Document referrer : ${document.referrer}`);
    if (document.referrer) {//only set referrer if it originated from a bmwgroup.net, bmwgroup.com or bmw.com address.
        if (isValidReferrer(document.referrer)) {
            localStorage.setItem("lastReferrer", document.referrer);
        }
    }
}



export const setRealm = (realm: string, assignedBy: string) => {
    if (realm !== localStorage.getItem("realm")) {
        localStorage.setItem("realm", realm?.replace("/", ""));
        localStorage.setItem("realmAssignedBy", assignedBy);
    }
};



export const getRealm = (doNotUseDefault?: boolean): string => {
    let realm = getParameterByName("realm");//try to read realm from current url.
    let assignedBy = "url";

    if (realm === null || realm === undefined || realm === "") {//if realm not specified in url - check referrer
        if (getLastDocumentReferrer() != null) {
            if (isExternalReferrer(getLastDocumentReferrer())) {
                realm = externalRealm
                assignedBy = "external referrer";
                console.warn(`last referrer was:: ${getLastDocumentReferrer()} - so setting realm to ${realm}`)
            }
            if (isInternalReferrer(getLastDocumentReferrer())) {
                realm = internalRealm
                assignedBy = "internal referrer";
                console.warn(`last referrer was:: ${getLastDocumentReferrer()} - so setting realm to ${realm}`)
            }
        }
    }

    if (realm === null || realm === undefined || realm === "") {// if url does not contain a realm, read from local storage last realm used.
        realm = localStorage.getItem("realm");
        assignedBy = "storage";
    }
    if (!doNotUseDefault) {
        if (realm === null || realm === undefined || realm === "") {// if url does not contain a realm, use default realm.
            realm = defaultRealm;
            assignedBy = "default bc blank";
        }
        if (realm !== "internetb2x" && realm !== "intranetb2x") {//if invalid value - asume default realm
            assignedBy = `default bc invalid realm ${realm}`;
            realm = defaultRealm;
        }
    }
    setRealm(realm, assignedBy);
    return realm;
};

export const isExternalRealm = () => {
    return (getRealm(true) === externalRealm);
};

export const clearStorage = () => {
    // eslint-disable-next-line no-console
    console.log("Clearing storage");
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("token_type");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("portal-Authorized");
    // sessionStorage.removeItem("callBackAlreadyPerformed")
};

export const getCurrentLoggedInUserFromDatabase = (user: PortalUser): Promise<any> => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: QUERY_GET_PORTAL_USER_EMAIL,
                variables: {email: user.email},
                fetchPolicy: "cache-first"
            })
            .then((portalUserData) => {
                if (portalUserData.data.PortalUser_getPortalUserInfoByEmail) {
                    user._id = portalUserData.data.PortalUser_getPortalUserInfoByEmail?.user_id;
                    user.user_id = portalUserData.data.PortalUser_getPortalUserInfoByEmail?.user_id;
                    user.role = portalUserData.data.PortalUser_getPortalUserInfoByEmail?.roleName;
                    setAuthority(portalUserData.data.PortalUser_getPortalUserInfoByEmail?.roleName);
                    resolve(user);

                } else {
                    reject("User not fully registered");
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getCurrentLoggedInUser = (): Promise<PortalUser> => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: VALIDATE_USER,
                fetchPolicy: "cache-first"
            })
            .then((response) => {
                console.debug('The user', response.data);
                if (response.data.validateUser.success) {
                    const user = {
                        ...response.data.validateUser.portalUser,
                        role: response.data.validateUser.portalUser?.roleName,
                        uid: response.data.validateUser.portalUser?.uid,
                        departmentnumber: response.data.validateUser.portalUser?.departmentnumber,
                        roles: response.data.validateUser.portalUser?.roles,
                        _id: response.data.validateUser?.user_id,
                        active: true,
                        displayName:
                            `${response.data.validateUser?.portalUser.firstname
                            } ${response.data.validateUser?.portalUser.lastname}`,
                        dateFormat: response.data.validateUser?.userPreferences?.dateFormat,
                        decimalSeparator: response.data.validateUser?.userPreferences?.decimalSeparator,
                        thousandSeparator: response.data.validateUser?.userPreferences?.thousandSeparator,
                        userSettingsId: response.data.validateUser?.userPreferences?.userSettingsId,
                        language: response.data.validateUser?.userPreferences?.language
                    };
                    setAuthority(response.data.validateUser?.portalUser.roleName);
                    setRealm(response.data.validateUser?.portalUser.realm, "token");
                    resolve(user);
                } else {
                    let errorMessage = undefined;
                    if (response.data.validateUser.errorCode !== undefined && response.data.validateUser.errorCode !== null) {
                        errorMessage = `${response.data.validateUser.errorCode}-${response.data.validateUser.errorDescription}`;
                    }
                    reject({message: errorMessage});
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export function replaceUrlDomain(url: string, domain: string): string {
    const domainRegex = /\.bmw(?:group)?\.(?:com|net)\/auth/;
    return url.replace(domainRegex, `.${domain}/auth`);
}

export const getWeEAMUrl = (): Promise<any> => {
    console.log(`getLastDocumentReferrer: ${getLastDocumentReferrer() }`);
    return new Promise((resolve, reject) => {
        client
            .query({
                query: QUERY_GET_WEBEAM_CONFIG,
                variables: {flow: {flowType: "Implicit"}},
                fetchPolicy: "cache-first"
            })
            .then((respond) => {
                if (respond.data.OAuth_getWebEamConfiguration) {
                    defaultRealm = respond.data.OAuth_getWebEamConfiguration.defaultRealm;
                    let authRedirectURL = isExternalRealm() ? respond.data.OAuth_getWebEamConfiguration.externalUrl : respond.data.OAuth_getWebEamConfiguration.url;                    
                    let urlWasSet = false;
                    console.warn(`authRedirectURL is ${authRedirectURL}`);
                    if(getAuthDomainParam() !== null && getAuthDomainParam() !== undefined){
                            const newUrl = replaceUrlDomain(authRedirectURL,getAuthDomainParam());                            
                            console.info(`found auth domain parameter - setting authRedirect url to ${newUrl}`);
                            authRedirectURL = newUrl;
                            urlWasSet = true;
                    }
                    //LEGACY BLOCK
                    if(!urlWasSet){//proceed with legacy redirect. we can remove this once all urls are updated in B2B and SGATE                    
                        if (getLastDocumentReferrer() != null) {
                            const referrerURL = new URL(getLastDocumentReferrer());
                            console.dir(referrerURL);
                            //redirect urls are setup for bmwgroup.net, if originated from a .com replace the auth url.
                            //both urls share the same jwt keys so safe to switch url.                            
                            if (referrerURL.hostname.endsWith(externalReferrerSGate)) {
                                authRedirectURL = authRedirectURL.replace("bmwgroup.net/auth", externalReferrerSGate.concat("/auth"));
                                console.info(`last referrer was:: ${getLastDocumentReferrer()} - so routing to new url ${authRedirectURL}`)
                            }                            
                            else if (referrerURL.hostname.endsWith(externalReferrerB2x)) {
                                authRedirectURL = authRedirectURL.replace("bmwgroup.net/auth", externalReferrerB2x.concat("/auth"));
                                console.info(`last referrer was:: ${getLastDocumentReferrer()} - so routing to new url ${authRedirectURL}`)
                            }
                        }
                        else if (isExternalRealm()) { //is seems like sGate do not set the referrer
                            authRedirectURL = authRedirectURL.replace("bmwgroup.net/auth", externalReferrerSGate.concat("/auth"));
                            // https://atc.bmwgroup.net/confluence/display/WEBEAM/WEN+OpenID+Connect+%28OIDC%29+-+Integration+Guide#WENOpenIDConnect(OIDC)IntegrationGuide-OAuth/OIDCURL-WhitelistforRealm:internetb2x
                            // Adding ocr_value minimum ocr value for string authentication
                            //authRedirectURL = `${authRedirectURL}&acr_values=strongAuth4000Service`;
                            console.info(`last referrer was:: ${getLastDocumentReferrer()} - so routing to new url ${authRedirectURL}`)
                        }
                    }
                    //LEGACY BLOCK
                    localStorage.setItem("authRedirectURL", authRedirectURL);
                    resolve(authRedirectURL);
                }
            })
            .catch((err) => {
                console.error(`getWeEAMUrl err : ${err}`);
                reject(err);
            });
    });
};


export const generateNonce = (someThing: string) =>
    `${dayjs(new Date()).format("YYYY_MM_DD__HH_mm_ss_SSS")}__${someThing}__${randomInt()}`;


export type jwtPromise = {
    acr: string;
    at_hash: string;
    aud: string;
    auditTrackingId: string;
    auth_time: string;
    azp: string;
    exp: string;
    iat: string;
    iss: string;
    nonce: string;
    realm: string;
    sub: string;
    tokenName: string;
    tokenType: string;
}

export const getJwtPromise = (): jwtPromise => {
    try {
        const jsonData = Buffer.from(localStorage.getItem('id_token').split('.')[1], 'base64');
        return JSON.parse(jsonData.toString());
    } catch (error) {
        console.error("ERROR RETRIEVING JWT PROMISE SECTION");
        console.error(error);
        return undefined;
    }
}

export const storeInitialPathParameters = () =>{
    //store realm
    if(getParameterByName(urlRealmParam) != null){
        setRealmParam(getParameterByName(urlRealmParam));
    }
    //store portal
    if(getParameterByName(urlPortalParam) != null){
        setPortalParam(getParameterByName(urlPortalParam));
    }
    //store authdomain
    if(getParameterByName(urlAuthDomainParam) != null){
        setAuthDomainParam(getParameterByName(urlAuthDomainParam));
    }
}