import React, { useState } from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { Col, Form, Select, Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { getSurveyStatusList } from '../pages/survey/__generated__/getSurveyStatusList';
import { SURVEY_STATUS_LIST_QUERY } from '../pages/survey/queries';
import { getI18n } from '../utils/Utils';
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from '../main/__generated__/getSelfEntitlementResultByFilter';
import { filteredEntitlementLookupForSelf } from '../main/entitlementService';
import _ from 'lodash';

type IAppComponentProps = {
    currentUser?: any;
    distinctEntitlements?: string[];
    entitlement: string;
    loading?: any;
    onChange: any;
    colSpan?: number;
    filter?: (arg:getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[], partnerName:string) => getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[];
} & WrappedComponentProps;

export const PartnerLookup: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [selectOptions, setSelectOption] = useState<
        getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]
    >([]);
    

    const removeDuplicated = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) =>
        Array.from(
            data
                .reduce((acc, item) => (item && item['partnerId'] && acc.set(item['partnerId'], item), acc), new Map())
                .values()
        );

    const partnerFilter = (partners: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[], partnerName: string) => {
        if (partners && props.filter) {
            return props.filter(partners, partnerName);
        }

        return partners;
    };



    const searchPartner = (partnerName) => {
        setLoading(true);
        //Handle custom loading logic
        if (props.loading !== null && props.loading !== undefined) {
            props.loading(true);
        }
        Promise.resolve(filteredEntitlementLookupForSelf(props.entitlement, partnerName, 1, 200))
            .then((response) => {
                const searchOption = removeDuplicated(partnerFilter(response.getSelfEntitlementResultByFilter.partners, partnerName));
                setSelectOption(searchOption);
                setLoading(false);
                if (props.loading !== null && props.loading !== undefined) {
                    props.loading(false);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                if (props.loading !== null && props.loading !== undefined) {
                    props.loading(false);
                }
            });
    };

    const handleSearch = _.debounce(function (value: string) {
        searchPartner(value);
    }, 1000);

    const handleChange = (value: string) => {
        props.onChange(value);
    };

    const { data } = useQuery<getSurveyStatusList>(SURVEY_STATUS_LIST_QUERY, {
        fetchPolicy: 'network-only',
    });

    return (
        <>
            <Col span={props.colSpan || 5}>
                <Spin size={'large'} spinning={loading}>
                    <Form.Item label={getI18n('partner-overview', 'Partner', intl)} name="partnerNo">
                        <Select
                            size={'middle'}
                            showSearch={true}
                            placeholder={getI18n(
                                'document-overview-search-message',
                                'Enter partner number or partner name',
                                intl
                            )}
                            onSearch={handleSearch}
                            onChange={handleChange}
                            defaultActiveFirstOption={true}
                            filterOption={false}
                            allowClear={true}
                            notFoundContent={null}
                        >
                            {selectOptions &&
                                selectOptions.map((item) => (
                                    <Select.Option key={`${item.partnerId}_${item.partnerNo}`} value={item.partnerNo}>
                                        {item.partnerNo} - {item.partnerName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Spin>
            </Col>
        </>
    );
};
