import React, { useEffect, useState } from 'react';
import { IAppComponentProps } from 'src/components';
import { Spin, Tabs, ConfigProvider, Select, Button, message} from 'antd'
import { useIntl } from 'react-intl';
import enUS from 'antd/lib/locale/en_US';
import deDe from "antd/lib/locale/de_DE";
import styles from '../../styles/scss/portal.scss';
import { saveAs } from 'save-as';
import { PageContainer } from '@ant-design/pro-layout';
import { getI18n } from "../../utils/Utils";
import { DownloadOutlined } from '@ant-design/icons';
import { getUserGuides_getUserGuidesDocList } from './__generated__/getUserGuides';
import { downloadUserGuideByBlobId } from "../help-faq/HelpUserGuideService"
import { getUserGuidesDocs } from "../../pages/help-faq/HelpUserGuideService";
import {Buffer} from "buffer";

interface UserGuideDetail {
    language: string;
    userGuideBlob: number;
    __typename: string;
  }

interface UserGuideDetail {
    language: string;
    userGuideBlob: number;
    __typename: string;
}
const HelpFaqUserGuides: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [loading, isLoading] = useState(false);
    const [userGuidesDocList, setUserGuidesDocList] = useState<getUserGuides_getUserGuidesDocList[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [selectedBlobId, setSelectedBlobId] = useState<number | null>(null);

const handleChange = (index: string, details: UserGuideDetail[]) => {
    console.log(details)
  const selectedBlobId = details.find((el) => el.language === index)?.userGuideBlob;
  setSelectedBlobId(selectedBlobId);
  console.log(`Selected Blob ID: ${selectedBlobId}`);
};

    useEffect(() => {
        setSelectedLanguage("EN");
        Promise.resolve(getUserGuidesDocs())
            .then((data) => {
                setUserGuidesDocList(data.getUserGuidesDocList);
            })
            .catch((err) => {
                message.error(intl.formatMessage({
                    id: "user-guides-documentLoadFailure",
                    defaultMessage: "Unable to load User Guides document list"
                }))
            });
    }, []);

    const handleDownload = (blobId) => {
         isLoading(true);

        Promise.resolve(downloadUserGuideByBlobId(blobId))
            .then((data) => {
                const mimeType = data.downloadUserGuideDocument.value.mimeType;
                const decodedData = Buffer.from(data.downloadUserGuideDocument.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob,data.downloadUserGuideDocument.value.fileName);
                message.success(intl.formatMessage({
                    id: "user-guide-downloadSuccess",
                    defaultMessage: "Document downloaded Successfully"
                }))
            })
            .catch((err) => {
                isLoading(false);

                message.error(intl.formatMessage({
                    id: "user-guide-downloadFailure",
                    defaultMessage: "Document Download failed"
                }))
                console.error(err);
            })
            .finally(() => {
                 isLoading(false);
                 setSelectedLanguage("EN");
                 setSelectedBlobId(null);
            });
    };

    const userGuideGroups = userGuidesDocList.reduce((groups, guide) => {
        if (!groups[guide.userGuideGroupId]) {
            groups[guide.userGuideGroupId] = guide.groupName;
        }
        return groups;
    }, {});

    const items = Object.entries(userGuideGroups).map(([groupId, group]) => ({
        key: groupId,
        label: group,
        children: userGuidesDocList.filter((el) => el.userGuideGroupId === parseInt(groupId)).map((el, i) => (
            <div key={i}>


                <div style={{margin:"20px"}}>
                    <div style={{display: 'inline-block', width:'350px'}}>
                        <h5 style={{color:'rgb(68,68,68)', padding:'0px',margin:'10px 0px'}}>{`${el.userGuideName}`}</h5>
                    </div>
                    <div style={{display: 'inline-block',width:'100px'}}>
                        <Select style={{width: '60px' }}
                            defaultValue={"EN"}
                            options={(el.details as unknown as UserGuideDetail[]).map((detail: UserGuideDetail) => ({ label: detail.language, value: detail.language }))}
                            onChange={(value) => handleChange( value , el.details as unknown as UserGuideDetail[])}
                           />
                    </div>
                    <div style={{display: 'inline-block',width:'100px'}}>
                                 <Button icon={<DownloadOutlined/>} size={"middle"}
                                       onClick={() => selectedBlobId !== null ? handleDownload(selectedBlobId) : handleDownload((el.details as unknown as UserGuideDetail[])[0].userGuideBlob)}>
                                     {intl.formatMessage({id: "info-portal-download"}).toUpperCase()}
                                 </Button>
                             </div>
                </div>
            </div>
        ))
    }));


    return (
                <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>

                    <Spin size={"large"} spinning={loading}>
                        <div style={{fontFamily: "BMW Group Condensed"}}>
                            <PageContainer
                                title={false}
                                content={''}
                                extraContent={''}
                                className={styles.users}
                                style={{background: 'white'}}
                            >
                                {<h3 style={{}}> {getI18n('help-document-download','Help' , intl)}</h3> }
                        {  <h4> {getI18n('help-document-download-instructions','Select the language of the document and click  on \"DOWNLOAD\":' , intl)}</h4>}

                        {/* { index === 0 ? <h4> {getI18n('help-document-download-instructions','Select the language of the document and click  on \"DOWNLOAD\":' , intl)}</h4> : null} */}
                        <Tabs defaultActiveKey="1" items={items}/>

                    </PageContainer>
                </div>
            </Spin>
        </ConfigProvider>

    );

};

export default HelpFaqUserGuides;


